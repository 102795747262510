import { useState } from 'react'
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FilterListIcon from '@mui/icons-material/FilterList';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useCtx } from 'hooks/useCtx';
import { Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { exportTransaction, importTransactions } from 'utils/transaction';

interface EnhancedTableToolbarProps {
  filterList: (filter: string[]) => void
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { filterList } = props;
  const { plans, showNotification, setProgress} = useCtx();
  const navigate = useNavigate();

  const [anchorFilterEl, setAnchorFilterEl] = useState<null | HTMLElement>(null);
  const filterOpen = Boolean(anchorFilterEl);

  const [anchorDownloadEl, setAnchorDownloadEl] = useState<null | HTMLElement>(null);
  const downloadOpen = Boolean(anchorDownloadEl);

  const [anchorUploadEl, setAnchorUploadEl] = useState<null | HTMLElement>(null);
  const uploadOpen = Boolean(anchorUploadEl);
  const [filter, setFilter] = useState(['all']);

  const filterClickedHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorFilterEl(event.currentTarget);
  };

  const downloadClickedHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorDownloadEl(event.currentTarget);
  };

  const uploadClickedHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUploadEl(event.currentTarget);
  };

  const filterSelectedHandler = (filter: string[]) => {
    filterList(filter);
    setFilter(filter);
    handleClose();
  }

  const handleClose = () => {
    setAnchorFilterEl(null);
    setAnchorDownloadEl(null);
    setAnchorUploadEl(null);
  };

  const importTransactionsClicked = async (planID: string) => {
    handleClose();
    await importTransactions(planID, setProgress, showNotification);
  }

  const exportTransactionsClicked = async (type: string) => {
    handleClose();
    setFilter(filter);
    await exportTransaction(type, filter);
  }

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            交易记录
          </Typography>
          <Tooltip title="添加交易记录">
            <IconButton onClick={()=>{navigate('/transactions/new')}}>
              <ReceiptIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="扫描交易记录">
            <IconButton onClick={()=>{navigate('/transactions/scan')}}>
              <CameraAltIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="导入交易记录">
            <IconButton
              id="upload-button"
              aria-controls={uploadOpen ? 'upload-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={uploadOpen ? 'true' : undefined}
              onClick={uploadClickedHandler}
            >
              <UploadIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="upload-menu"
            aria-labelledby="upload-button"
            anchorEl={anchorUploadEl}
            open={uploadOpen}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem>选择要导入的计划</MenuItem>
            <Divider />
            {plans.map((plan) => (
                <MenuItem 
                  key={plan.id}
                  onClick={()=>importTransactionsClicked(plan.id)}>{plan.name}
                </MenuItem>
            ))}
            <Divider />
            <MenuItem onClick={() => navigate('/plans/new')}>创建新计划</MenuItem>
          </Menu>
          <Tooltip title="导出交易记录">
            <IconButton
              id="download-button"
              aria-controls={downloadOpen ? 'download-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={downloadOpen ? 'true' : undefined}
              onClick={downloadClickedHandler}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="download-menu"
            aria-labelledby="download-button"
            anchorEl={anchorDownloadEl}
            open={downloadOpen}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
              <MenuItem 
                onClick={()=>exportTransactionsClicked('json')}>json格式
              </MenuItem>
              <MenuItem 
                onClick={()=>exportTransactionsClicked('csv')}>csv格式
              </MenuItem>
          </Menu>
          <Tooltip title="筛选交易记录">
            <IconButton
              id="filter-button"
              aria-controls={filterOpen ? 'filter-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={filterOpen ? 'true' : undefined}
              onClick={filterClickedHandler}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="filter-menu"
            aria-labelledby="filter-button"
            anchorEl={anchorFilterEl}
            open={filterOpen}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {plans.map((plan) => (
                <MenuItem 
                  key={plan.id}
                  onClick={()=>filterSelectedHandler(['byPlan', plan.name])}>{plan.name}
                </MenuItem>
            ))}
            <Divider />
            <MenuItem onClick={()=>filterSelectedHandler(['all'])}>All</MenuItem>
          </Menu>
      </Toolbar>
    </>

  )
}

export default EnhancedTableToolbar
