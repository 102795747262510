import { TargetStatus } from "./TargetBlock";
import { Divider, Grid, LinearProgress, List, ListItemButton, Typography } from '@mui/material';
import { showCurrency } from "utils/currency";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useState } from "react";
import { getPriceByFundCodeAndDateRange } from "services/core/fundPrice";
import { toLocalISOString } from "utils/time";

interface TargetsStatusProps {
  targets: TargetStatus[]
}

function TargetsStatus({ targets }: TargetsStatusProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingItem, setLoadingItem] = useState(0);

  const fundClickedHandler = (fundCode: string) => {
    window.open(`https://fund.eastmoney.com/${fundCode}.html`, '_blank', 'noopener,noreferrer');
  }

  const refreshButtonClickedHandler = async (fundCode: string, index: number) => {
    setIsLoading(true);
    setLoadingItem(index);
    const today = toLocalISOString(new Date()).slice(0,10);
    let startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    const sDate = toLocalISOString(startDate).slice(0,10);

    try {
      const prices = await getPriceByFundCodeAndDateRange(fundCode, sDate, today, 20000);
      targets[index] = {
        ...targets[index],
        currentPrice: prices[prices.length - 1].value,
        priceRate: parseFloat(prices[prices.length - 1].value) / parseFloat(targets[index].targetPrice),
      }
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  }

  return (
    <List component="nav">
      {targets.map((target, index) => (
        <div key={index}>
          <ListItemButton>
            <Grid container onClick={() => {fundClickedHandler(target.fundCode)}}>
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary">
                  {`${target.fundCode} ${target.fundName}`}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color="text.secondary">
                  余额: {target.costGap === 0 ? (
                    <span style={{color: 'red'}}>{showCurrency(target.costGap.toString())}</span>
                  ) : (
                    <span style={{color: 'green'}}>{showCurrency(target.costGap.toString())}</span>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color="text.secondary">
                  目标价: ¥{parseFloat(target.targetPrice).toFixed(4)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  // justifyContent="flex-end"
                  display="flex" 
                  alignItems="center"
                >
                  当前价: {target.currentPrice === "询价超时"? (
                    <>
                      <span style={{color: 'red'}}>{target.currentPrice}</span>
                      <RefreshIcon 
                        fontSize="small"
                        color="primary"
                        onClick={()=>{refreshButtonClickedHandler(target.fundCode, index)}}
                      />
                    </>
                  ) : (
                    <span>¥{parseFloat(target.currentPrice).toFixed(4)}</span>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  display="flex" 
                  alignItems="center"
                >
                  价格比: {target.priceRate < 1 ? (
                    <>
                      <span style={{color: 'green'}}>{target.priceRate.toFixed(2)}</span>
                      <SentimentSatisfiedAltIcon color="success" fontSize="small" sx={{ml:1}}/>
                    </>
                  ) : (
                    <>
                      <span style={{color: 'red'}}>{target.priceRate.toFixed(2)}</span>
                      <SentimentVeryDissatisfiedIcon color="error" fontSize="small" sx={{ml:1}}/>
                    </>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </ListItemButton>
          {isLoading && loadingItem === index ? (
              <LinearProgress />
            ) : (<></>)
          }
          <Divider/>
        </div>
      ))}
    </List>
  )
}

export default TargetsStatus