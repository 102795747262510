import { Box, Button, Grid, Modal, Paper, Typography } from '@mui/material';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useState } from 'react';
import { Plan } from 'models';
import { useNavigate } from 'react-router-dom';
import { useCtx } from 'hooks/useCtx';
import { DataStore } from 'aws-amplify';
import { showCurrency } from 'utils/currency';
import { updateUserData } from 'services';

interface PlanItemProps {
  plan: Plan
}

function PlanItem({ plan }: PlanItemProps) {
  const navigate = useNavigate();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const { showNotification, setProgress } = useCtx();

  const deleteConfirmHandler = async () => {
    try {
      await DataStore.delete(plan);
      await updateUserData(false);
      setProgress(100);
      setTimeout(()=>{
        showNotification({ isOpen: true, severity: 'success', message: '基金组合已删除！' });
        setProgress(0);
      },500);
    } catch (err) {
      console.error(err);
      showNotification({ isOpen: true, severity: 'error', message: err as string });
    }
  }

  return (
    <>
      <Box >
        <Grid container alignItems="flex-end">
          <Grid item xs={10}>
            <Grid container 
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
              onClick={() => navigate('/dashboard/plan/'+ plan.id)}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="div">
                  {plan.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom variant="body2" color="text.secondary">
                  收益：{showCurrency(plan.daySummary?.floatProfit)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                  {plan.daySummary?.dayRate[0] === '-' ? (
                    <>
                      <Typography gutterBottom 
                        variant="body2" 
                        display="flex" 
                        alignItems="center"
                      >
                        涨跌：<span style={{color: 'green'}}>{plan.daySummary?.dayRate}%</span>
                        <TrendingDownIcon color='success' fontSize='small'/>
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography gutterBottom 
                        variant="body2" 
                        display="flex" 
                        alignItems="center"
                      >
                        涨跌：<span style={{color: 'red'}}>{plan.daySummary?.dayRate}%</span>
                        <TrendingUpIcon color='error' fontSize='small'/>
                      </Typography>
                    </>
                  )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  收益率：{plan.daySummary?.floatRate[0] === '-' ? (
                    <span style={{color: 'green'}}>{plan.daySummary?.floatRate}%</span>
                  ) : (
                    <span style={{color: 'red'}}>{plan.daySummary?.floatRate}%</span>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  市值：{showCurrency(plan.daySummary?.value)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid item>
              <Button 
                sx = {{ marginLeft: 'auto'}}
                size="small" 
                onClick={() => {navigate(`/plans/${plan.id}`)}}
              >
                编辑
              </Button>
            </Grid>
            <Grid item>
              <Button 
              size="small" 
              onClick={() => setDeleteConfirmOpen(true)}
              color='error'
              >
                删除
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Modal
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <h2 id="modal-title">删除基金组合</h2>
          <p id="modal-description">
            确认删除该基金组合吗？所有相关交易记录也会一并删除！
          </p>
          <Button color='error' onClick={deleteConfirmHandler}>删除</Button>
          <Button onClick={()=>setDeleteConfirmOpen(false)}>取消</Button>
        </Box>
      </Modal>
    </>
  )
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default PlanItem
