import { Backdrop, Box, Button, CircularProgress, Grid, Paper } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import Tesseract from "tesseract.js"
import TransactionForm, { FormData } from "./TransactionForm";
import { useCtx } from "hooks/useCtx";
import { convertFromText } from "utils/transaction";



function TransactionScan() {

  const webcamRef = useRef<Webcam>(null);
  const [imageSrc, setImageSrc] = useState('');
  const [formData, setFormData] = useState<FormData>();
  const { plans, showNotification } = useCtx();
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [isPhoto, setIsPhoto] = useState(true);

  const capture = useCallback(
    () => {
      const src = webcamRef?.current?.getScreenshot({width:1920, height:1440}) as string;
      setIsPhoto(true);
      setImageSrc(src);
      if (src) recognize(src);
    },
    [webcamRef]
  );

  const scanFromFile = () => {
    try {
      var input = document.createElement('input');
      input.type = 'file';
      input.accept = '.jpeg,.jpg,.png';
  
      input.addEventListener("change", async () => {
        if (input.files){
          const reader = new FileReader();
          reader.onload = (e) => {
            const result = e.target?.result as string;
            setIsPhoto(false);
            setImageSrc(result);
          }
          reader.readAsDataURL(input.files[0]);

          recognize(input.files[0]);
        }
      }, false)  
  
      input.click();
  
    } catch (err) {
      console.error(err);
      throw(err);
    }
  }

  const recognize = (image: string | File) => {
    setBackdropOpen(true);
    Tesseract.recognize(
      image,
      'chi_sim',
      // { logger: m => console.log(m) }
    ).then(({ data: { text } }) => {
      console.info(text);

      let newFormData: FormData | undefined = convertFromText(text);
      if (newFormData) {
        newFormData.plan = plans[0];
      } else {
        showNotification({ severity: 'error', isOpen: true, message: '无法识别，请重新拍照或上传清晰截图！'})
      }
      setFormData(newFormData);
      setBackdropOpen(false);
    });
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Paper sx={{mt:2, padding:1}}>
            <div className="webcam-container">
              <Box
                // sx={{border: 1}}
                display="flex" 
                justifyContent="center"
              >
                {imageSrc ? ( isPhoto ? (
                    <img src={imageSrc} style={{
                      width: "100%", 
                      border: "1px solid #555"
                    }}/>
                  ) : (
                    <img src={imageSrc} style={{
                      width: "auto", 
                      height: "auto",
                      maxHeight: "600px",
                      border: "1px solid #555"
                    }}/>
                  )
                ) : (
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width="100%"
                    style={{border: "1px solid #555"}}
                  />
                )}
              </Box>
              <Box
                display="flex" 
                justifyContent="center"
              >
                <Button
                  size="large"
                  onClick={capture}
                >
                  拍照扫描
                </Button>
                <Button
                  size="large"
                  onClick={scanFromFile}
                >
                  上传截图扫描
                </Button>
              </Box>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <TransactionForm data={formData}/>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress />
      </Backdrop>

    </>
  )
}

export default TransactionScan
