import { Paper, styled, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Transaction } from 'models'
import { useNavigate } from 'react-router-dom'
import { showCurrency } from 'utils/currency'

interface TransactionTableProps {
    transactions: Transaction[]
  }
function TransactionTable({ transactions }: TransactionTableProps) {
  const StyledTableCell = styled(TableCell)({
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10
  })

  const navigate = useNavigate();

  const rowClickedHandler = (id: string) => {
    navigate(`/transactions/${id}`);
  }

  return (
    <Paper sx={{marginTop: 1}}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell>类型</StyledTableCell>
            <StyledTableCell align="right">金额</StyledTableCell>
            <StyledTableCell align="right">份额</StyledTableCell>
            <StyledTableCell align="right">价格</StyledTableCell>
            <StyledTableCell align="right">费用</StyledTableCell>
            <StyledTableCell align="right">日期</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((transaction) => (
            <TableRow
              hover
              key={transaction.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
              onClick={() => rowClickedHandler(transaction.id)}
            >
              <StyledTableCell component="th" scope="row">
                {transaction.type.substring(0,2)}
              </StyledTableCell>
              <StyledTableCell align="right">{showCurrency(transaction.amount)}</StyledTableCell>
              <StyledTableCell align="right">{showCurrency(transaction.volume)}</StyledTableCell>
              <StyledTableCell align="right">{showCurrency(transaction.price)}</StyledTableCell>
              <StyledTableCell align="right">{showCurrency(transaction.fee)}</StyledTableCell>
              <StyledTableCell align="right">{transaction.date.substring(0,10)}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default TransactionTable
