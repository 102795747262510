import { Button, Card, CardActions, CardContent, Chip, FormControl, Grid, IconButton, Input, InputLabel, Stack, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { DataStore } from '@aws-amplify/datastore';
import { useCtx } from 'hooks/useCtx';
import { DaySummary, FundTarget, Plan } from 'models';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toCSTDateISOString, toLocalISOString } from 'utils/time';
import AddIcon from '@mui/icons-material/Add';
import { getPriceByFundCodeAndDateRange } from 'services/core/fundPrice';

function PlanForm() {
  const params = useParams();

  const [plan, setPlan] = useState(new Plan({ name: '', description: '' }));
  
  const initialTarget: FundTarget = {
    fundCode: '',
    targetCost: '',
    targetPrice: ''
  }
  const [targets, setTargets] = useState<FundTarget[]>([]);
  const [target, setTarget] = useState(initialTarget);

  const navigate = useNavigate();
  const {showNotification} = useCtx();

  useEffect(() => {
    const sub = DataStore.observeQuery(Plan, (p) =>
      p.id('eq', params.planID??'')
    ).subscribe(({ items }) => {
      if (items.length > 0) setPlan(items[0]);

      if(items[0].targets && items[0].targets[0]) {
        setTargets(items[0].targets as FundTarget[])
      }  
    });

    return () => {
      sub.unsubscribe();
    };
  },[]);

  const setInput = (key: string)=> (event: any) => {
    setPlan(Plan.copyOf(plan!, (draft) => {
      if (key === 'name') draft.name = event.target.value;
      if (key === 'description') draft.description = event.target.value;
    }));
  }

  const setTargetInput = (key: string)=> (event: any) => {
    setTarget({
      ...target,
      [key]: event.target.value
    })
  }

  const removeTargetClickHandler = (index: number) => {
    let newTargets = targets.slice(0, index);
    newTargets = newTargets.concat(targets.slice(index + 1, targets.length))
    setTargets(newTargets);
    setPlan(Plan.copyOf(plan, (updated) => {
      updated.targets = newTargets;
    }));
  }

  const addTargetClickHandler = async () => {
    if (await isValidTarget(target)) {
      const newTargets = [...targets, target];
      setTargets(newTargets);
      setTarget(initialTarget);
      setPlan(Plan.copyOf(plan, (updated) => {
        updated.targets = newTargets;
      }));
    } 
  }

  const isValidTarget = async (target: FundTarget): Promise<boolean> => {
    const reg = new RegExp('\\d{6}');
    if (!reg.test(target.fundCode)) {
      showNotification({ isOpen: true, severity: 'error', message: '基金代码必须为6位数字!'})
      return false;
    }

    const filteredTargets = targets.filter(t => t.fundCode === target.fundCode);
    if (filteredTargets.length > 0) {
      showNotification({ isOpen: true, severity: 'error', message: '基金代码重复!'})
      return false;
    }

    const today = toLocalISOString(new Date()).slice(0,10);
    let startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    const sDate = toLocalISOString(startDate).slice(0,10);
    try {
      const price = await getPriceByFundCodeAndDateRange(target.fundCode, sDate, today, 20000);
    
      if (!price || price.length === 0) {
        showNotification({ isOpen: true, severity: 'error', message: '基金代码错误或验证失败,请稍后再试!'})
        return false;
      }
    } catch (error) {
      showNotification({ isOpen: true, severity: 'error', message: '基金代码错误或验证失败,请稍后再试!'})
      return false;
  }

    const tPrice = Number(target.targetPrice); 
    if (!tPrice || tPrice <= 0) {
      showNotification({ isOpen: true, severity: 'error', message: '目标价格错误,必须为大于0的数字!'})
      return false;
    }

    const tCost = Number(target.targetCost);
    if (!tCost || tCost <= 0) {
      showNotification({ isOpen: true, severity: 'error', message: '目标成本错误,必须为大于0的数字!'})
      return false;
    }

    return true;
  }

  const saveClickHandler = async () => {
    let toSavePlan: Plan;

    if (!params.planID) {
      toSavePlan = Plan.copyOf(plan!, (draft) => {
        draft.daySummary = new DaySummary({
          date: toCSTDateISOString(new Date()),
          totalVolume: '0',
          totalCost: '0',
          averageCost: '',
          price: '',
          value: '0',
          floatProfit: '0',
          floatRate: '0',
          sellProfit: '0',
          outAmount: '0',
          totalProfit: '0',
          totalRate: '0',
          dayRate: '0',
        });
      });
    } else {
      toSavePlan = plan;
    }

    try {
      await DataStore.save(toSavePlan);

      showNotification({isOpen: true, severity: 'success', message: '基金组合已保存！'});
      navigate(-1);

    } catch (err) {
      console.error(err);
      showNotification({isOpen: true, severity: 'error', message: err as string});
    }
  }

  const cancelClickHandler = () => {
    navigate(-1);
  } 

  return (
    <Container maxWidth="sm" sx={{mt: 2}}>
      <Card sx={{
        width: 400,
        mt: 2
      }}>
        <CardContent>
          <FormControl fullWidth sx={{ my: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">组合名称</InputLabel>
            <Input
              id="standard-adornment-amount"
              value={plan.name}
              onChange={setInput('name')}
            />
          </FormControl>
          <FormControl fullWidth sx={{ my: 1 }} variant="standard">
            <InputLabel htmlFor="standard-adornment-amount">详情/备注</InputLabel>
            <Input
              id="standard-adornment-amount"
              value={plan.description}
              onChange={setInput('description')}
              multiline
              rows={2}
            />
          </FormControl>
          <Typography>购买目标</Typography>
          <Typography variant='caption'>基金当日净值低于目标价格且已购成本低于目标成本时提醒。</Typography>
          {targets.map((target, index)=>
            <Chip 
              key={index}
              sx={{mb:0.5}}
              label={`基金代码:${target.fundCode} 目标价格:${target.targetPrice} 目标成本:${target.targetCost}`}
              onDelete={()=>removeTargetClickHandler(index)}
            />
          )}
          <Stack direction="row">
            <Grid container>
              <Grid item xs={4}>
                <FormControl fullWidth sx={{ my: 1 }} variant="standard">
                  <InputLabel htmlFor="target-fund-code">基金代码</InputLabel>
                  <Input
                    id="target-fund-code"
                    value={target.fundCode}
                    onChange={setTargetInput("fundCode")}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth sx={{ my: 1 }} variant="standard">
                  <InputLabel htmlFor="target-price">目标价格</InputLabel>
                  <Input
                    id="target-price"
                    value={target.targetPrice}
                    onChange={setTargetInput("targetPrice")}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth sx={{ my: 1 }} variant="standard">
                  <InputLabel htmlFor="target-cost">目标成本</InputLabel>
                  <Input
                    id="target-cost"
                    value={target.targetCost}
                    onChange={setTargetInput("targetCost")}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <IconButton 
              color="primary" 
              size="small"
              onClick={addTargetClickHandler}
              sx={{py:2}}
            >
              <AddIcon fontSize="small"/>
            </IconButton>
          </Stack>
        </CardContent>
        <CardActions>
          <Button 
            sx={{ marginLeft: 'auto' }}
            onClick={saveClickHandler}
            size='large'
          >
            保存
          </Button>
          <Button 
            color="error"
            onClick={cancelClickHandler}
            size='large'
          >
            取消
          </Button>
        </CardActions>
      </Card>
    </Container>
  )
}

export default PlanForm
