import { MouseEvent, useEffect, useState } from 'react';
import Auth from '@aws-amplify/auth';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import SavingsIcon from '@mui/icons-material/Savings';
import CalculateIcon from '@mui/icons-material/Calculate';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { DataStore } from 'aws-amplify';
import { Plan } from 'models';
import { recalculateData } from 'services';
import { Backdrop, CircularProgress, Divider, ListItemIcon } from '@mui/material';
import { CameraAlt, Settings } from '@mui/icons-material';


interface HeaderProps {
  plans: Plan[],
  setProgress: (progress: number) => void
}

function Header ({ plans, setProgress }: HeaderProps) {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const pages = ['交易记录'];
  const links = ['transactions/list']
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [backdropOpen, setBackdropOpen] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      setUsername(user.attributes.name);
    })
  })

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navMenuClickHandler = (event: MouseEvent<HTMLElement>, index: number) => {
    navigate(links[index]);
    handleCloseNavMenu(event);
  }

  const scanClickedHandler = () => {
    navigate('/transactions/scan');
    handleCloseUserMenu();
  }

  const settingClickedHandler = () => {
    navigate('/configuration/');
    handleCloseUserMenu();
  }

  const recalculateClickedHandler = async () => {
    handleCloseUserMenu();
    setBackdropOpen(true);
    await recalculateData(setProgress);
    setBackdropOpen(false);
  }

  const logoutClickedHandler = () => {
    handleCloseUserMenu();
    Auth.signOut();
    DataStore.clear();
    navigate('/home/');
  }

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <SavingsIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={()=>{navigate('/home')}}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            Bina
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page,index) => (
              <Button
                key={page}
                onClick={() => navigate(links[index])}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
            {plans.map((plan,index) => (
              <Button
                key={plan.id}
                onClick={() => navigate('/dashboard/plan/'+ plan.id)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {plan.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="设置">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={username} src="/static/images/defaultImage.png" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={scanClickedHandler}>
                <ListItemIcon>
                  <CameraAlt/>
                </ListItemIcon>
                <Typography textAlign="center">扫描交易记录</Typography>
              </MenuItem>
              <MenuItem onClick={settingClickedHandler}>
                <ListItemIcon>
                  <Settings/>
                </ListItemIcon>
                <Typography textAlign="center">设置</Typography>
              </MenuItem>
              <MenuItem onClick={recalculateClickedHandler}>
                <ListItemIcon>
                  <CalculateIcon/>
                </ListItemIcon>
                <Typography textAlign="center">重新计算所有数据</Typography>
              </MenuItem>
              <Divider/>
              <MenuItem onClick={logoutClickedHandler}>
                <ListItemIcon>
                  <LogoutIcon/>
                </ListItemIcon>
                <Typography textAlign="center">退出</Typography>
              </MenuItem>
              {/* <MenuItem onClick={()=>{fetchFindInfo()}}>
                <ListItemIcon>
                  <LogoutIcon/>
                </ListItemIcon>
                <Typography textAlign="center">TestButton</Typography>
              </MenuItem> */}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress />
      </Backdrop>
    </AppBar>
  );
};

export default Header;
