import { NotificationProps } from "components";
import { Plan } from "models";
import { useOutletContext } from "react-router-dom";

type ContextType = { 
    plans: Plan[], 
    showNotification: (notification: NotificationProps) => {},
    setProgress: React.Dispatch<React.SetStateAction<number>>
};

export function useCtx() {
    return useOutletContext<ContextType>();
}