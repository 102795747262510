import { 
  Box, Button, IconButton, Modal, Paper, Table, TableBody, 
  TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { DataStore } from '@aws-amplify/datastore';
import { Transaction } from 'models'
import React, { useEffect, useState } from 'react'
import EnhancedTableToolbar from './EnhancedTableToolbar';
import { Data, getComparator, Order } from './sort';
import { useNavigate } from 'react-router-dom';
import { useCtx } from 'hooks/useCtx';
import { updateDataAfterTransactionMutation } from 'services';
import { showCurrency } from 'utils/currency';

interface TransactionListProps {
  data: Data[]
}
function TransactionList({ data }: TransactionListProps) {
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('date');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [rows, setRows] = useState<Data[]>(data);
  const navigate = useNavigate();
  const { showNotification, setProgress } = useCtx();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [toDeleteTransactionID, setToDeleteTransactionID] = useState('');

  useEffect(() => {
    setRows(data);
  },[data]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const createSortHandler =
  (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowClickedHandler = (id: string) => {
    navigate(`/transactions/${id}`);
  }

  const deleteButtonClickedHandler = (id: string) => {
    setToDeleteTransactionID(id);
    setConfirmOpen(true);
  }

  const deleteConfirmHandler = async () => {
    try{
      const toDeleteTransaction = await DataStore.query(Transaction, toDeleteTransactionID);
      await DataStore.delete(toDeleteTransaction!);
      updateDataAfterTransactionMutation(toDeleteTransaction!, setProgress);
      setConfirmOpen(false);
      showNotification({isOpen: true, severity: 'success', message: '交易记录已删除！'});
    
    } catch (err) {
      console.error(err);
      showNotification({isOpen: true, severity: 'error', message: `删除失败，请联系管理员. ${err}`});
    }
  }

  const filterList = (filter: string[]) => {
    if (filter[0] === 'byPlan') {
      setRows(data.filter(t => t.plan === filter[1]));
    } else if (filter[0] === 'byFund') {
      setRows(data.filter(t => t.fundCode === filter[1]));
    } else {
      setRows(data);
    }
    
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar
            filterList={filterList}
          />
          <Box display={{ xs: 'none', md: 'block' }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 200 }}
                aria-labelledby="tableTitle"
                size='small'
              >
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ minWidth: headCell.minWidth, maxWidth: headCell.maxWidth }}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                      <TableCell />

                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows.slice().sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              cursor: "pointer",
                              maxWidth: 100
                            }}
                            onClick={() => rowClickedHandler(row.id)}
                          >
                            {row.fundCode}
                          </TableCell>
                          <TableCell 
                            sx={{
                              px: 0,
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              cursor: "pointer",
                              maxWidth: 200
                            }}
                            onClick={() => rowClickedHandler(row.id)}
                          >
                            {row.fundName}
                          </TableCell>
                          <TableCell 
                            sx={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              cursor: "pointer",
                              maxWidth: 150
                            }}
                            onClick={() => rowClickedHandler(row.id)}
                          >
                            {row.plan}
                          </TableCell>
                          <TableCell 
                            sx={{
                              px: 0,
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              cursor: "pointer"
                            }}
                            onClick={() => rowClickedHandler(row.id)}
                          >
                            {row.type}
                          </TableCell>
                          <TableCell 
                            align="right"
                            sx={{
                              px: 0,
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              cursor: "pointer"
                            }}
                            onClick={() => rowClickedHandler(row.id)}
                          >
                            {showCurrency(row.amount)}
                          </TableCell>
                          <TableCell 
                            align="right"
                            sx={{
                              px: 0,
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              cursor: "pointer"
                            }}
                            onClick={() => rowClickedHandler(row.id)}
                          >
                            {showCurrency(row.volume)}
                          </TableCell>
                          <TableCell 
                            align="right"
                            sx={{
                              px: 0,
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              cursor: "pointer"
                            }}
                            onClick={() => rowClickedHandler(row.id)}
                          >
                            {showCurrency(row.price)}
                          </TableCell>
                          <TableCell 
                            align="right"
                            sx={{
                              px: 0,
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              cursor: "pointer"
                            }}
                            onClick={() => rowClickedHandler(row.id)}
                          >
                            {showCurrency(row.fee)}
                          </TableCell>
                          <TableCell 
                            sx={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              cursor: "pointer"
                            }}
                            onClick={() => rowClickedHandler(row.id)}
                          >
                            {row.date.slice(0,10)}
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "24px",
                              padding: 0,
                            }}
                          >
                            <IconButton
                              onClick={() => deleteButtonClickedHandler(row.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 33 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <Modal
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="modal-title">删除确认</h2>
          <p id="modal-description">
            确定删除该交易记录?
          </p>
          <Button color='error' onClick={deleteConfirmHandler}>删除</Button>
          <Button onClick={()=>setConfirmOpen(false)}>取消</Button>
        </Box>
      </Modal>

    </>
  )
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  minWidth: number;
  maxWidth: number;
  numeric: boolean;
}

export const headCells: readonly HeadCell[] = [
  {
    id: 'fundCode',
    numeric: false,
    disablePadding: false,
    label: '基金代码',
    minWidth: 120,
    maxWidth: 120
  },
  {
    id: 'fundName',
    numeric: false,
    disablePadding: true,
    label: '基金名称',
    minWidth: 90,
    maxWidth: 240
  },
  {
    id: 'plan',
    numeric: false,
    disablePadding: false,
    label: '基金组合',
    minWidth: 120,
    maxWidth: 200
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    label: '交易类型',
    minWidth: 90,
    maxWidth: 90
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: true,
    label: '成交金额',
    minWidth: 90,
    maxWidth: 200
  },
  {
    id: 'volume',
    numeric: true,
    disablePadding: true,
    label: '成交份额',
    minWidth: 90,
    maxWidth: 200
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: true,
    label: '成交价格',
    minWidth: 90,
    maxWidth: 90
  },
  {
    id: 'fee',
    numeric: true,
    disablePadding: true,
    label: '手续费',
    minWidth: 90,
    maxWidth: 90
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: '交易日期',
    minWidth: 120,
    maxWidth: 120
  },
];

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default TransactionList
