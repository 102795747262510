import { CircularProgress, Paper } from '@mui/material';
import Highcharts from "highcharts/highstock";
import HighchartsReact from 'highcharts-react-official';
import { Plan, PlanFund } from 'models'
import { useEffect, useState } from 'react'

interface PieChartProps {
  items: Plan[] | PlanFund[]
}

type PieData = {
  name: string,
  y: number
}[]

type APDrillDown = {
  name: string,
  categories: string[],
  data: number[]
}

type APData = {
  y: number,
  drilldown: APDrillDown
}

type AggrePiePack = {
  type: string[]
  data: APData[]
}

function PieChart({ items }: PieChartProps) {
  const [options, setOptions] = useState<any>();

  const makeOneSeriesDataAndSetOptions = () => {
    let data: PieData = [];
    const sortedItems = items.sort(
      (a, b) => {
        if (!a.daySummary) return 0;

        return parseFloat(a.daySummary!.value!) < parseFloat(b.daySummary!.value!) 
        ? 1 : -1
      }
    );
    for (const item of sortedItems as Plan[]) {
      data.push({
        name: item.name,
        y: parseFloat(item.daySummary!.value)
      })
    }

    setOptions({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      credits: {
        enabled: false
      },
      legend: {
        maxHeight: 85
      },
      title: {
        text: '投资组成结构',
        align: 'center',
        verticalAlign: 'top',
        y: 20,
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          },
          showInLegend: true,
          size: '90%'
        },
        series: {
          borderWidth: 1,
        }
      },
      series: [{
        name: '净值占比',
        colorByPoint: true,
        innerSize: '50%',
        data: data
      }]
    })
  }

  const makeTwoSeriesDataAndSetOptions = () => {
    let pieData : APData[] = [];
    let fundTypes : string[] = [];
    let unique : {[key:string]:boolean} = {};
    const sortedItems = items.sort(
      (a, b) => {
        if (!a.daySummary) return 0;

        return parseFloat(a.daySummary!.value!) < parseFloat(b.daySummary!.value!) 
        ? 1 : -1
      }
    ) as PlanFund[];

    sortedItems.forEach(fund => {
      if (!unique[fund.fund.type]) {
        unique[fund.fund.type] = true
      }
    });

    fundTypes = Object.keys(unique);

    fundTypes.forEach(type => {
      let fundNames : string[] = [];
      let fundValues : number[] = [];
      let totalValue = 0;

      sortedItems.forEach(fund => {
        if (!fund.fund.type.localeCompare(type)) {
          fundNames.push(fund.fund.fullName);
          const valueSum = parseFloat(fund.daySummary.value);
          fundValues.push(valueSum);
          totalValue += valueSum;
        }
      });

      let drilldown : APDrillDown = {
        name: type,
        categories: fundNames,
        data: fundValues
      }

      pieData.push({
        y: totalValue,
        drilldown: drilldown
      });
    });

    let typeData = [];
    let fundData = [];
    let i, j;
    let dataLen = pieData.length
    let drillDataLen
    for (i = 0; i < dataLen; i += 1) {
      typeData.push({
        name: fundTypes[i],
        y: pieData[i].y
      })
      drillDataLen = pieData[i].drilldown.data.length
      for (j = 0; j < drillDataLen; j += 1) {
        fundData.push({
          name: pieData[i].drilldown.categories[j],
          y: pieData[i].drilldown.data[j]
        })
      }
    }

    setOptions({
      chart: {
        type: 'pie',
      },
      credits: {
        enabled: false
      },
      legend: {
        maxHeight: 85
      },
      title: {
        text: '持仓分布',
        style: {
          color: 'rgb(158, 161, 176)'
        }
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '50%'],
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        },
        series: {
          borderWidth: 1,
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      series: [{
        name: '净值占比',
        data: typeData,
        size: '80%',
        innerSize: '50%',
      }, {
        name: '净值占比',
        data: fundData,
        size: '120%',
        innerSize: '60%',
        showInLegend: true
      }],
    });
  }

  useEffect(() => {
    if (items[0] instanceof Plan) {
      makeOneSeriesDataAndSetOptions();
    } else {
      makeTwoSeriesDataAndSetOptions();
    }
  },[items])

  return (
    <>
      {options ? (
        <Paper sx={{marginTop: 1}}>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          />
        </Paper>
      ) : (
        <CircularProgress />
      )}
    </>
  )
}

export default PieChart
