import { DataStore } from "aws-amplify";
import { TransactionType } from "constants/TransactionType";
import { FundInfo, Plan, Transaction } from "models";
import fundInfo from '../constants/fundInfo.json';
import { toCSTDateISOString } from 'utils/time';
import { updateUserData, recalculatePlanData } from "services";

async function importTransactions(planID: string, progress: Function, showNotification: Function) {
  const plan = await DataStore.query(Plan, planID);
  if (!plan) {
    console.error(`Plan doesn't exist!`);
    return;
  }

  try {
    var input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv,.json';

    input.addEventListener("change", async () => {
      if (input.files){
        let file = input.files[0];
        const reader = new FileReader();
        reader.onload = async function (event) {
          if (event.target) {
            progress(0);
            const text = event.target.result;
            let data: CsvData[] | JsonData[] = [];
            if (file.type === 'text/csv'){
              data = csvToArray(text as string) as CsvData[];
            } else if (file.type === 'application/json') {
              data = JSON.parse(text as string) as JsonData[];
            }
            progress(10);
            await convertAndSave(data, planID, progress, showNotification);
            await recalculatePlanData(plan);
            await updateUserData(false);
            progress(100);

          }
        };
        reader.readAsText(file);
      }
    }, false)  

    input.click();

  } catch (err) {
    console.error(err);
    throw(err);
  }
}

type CsvData = {
  amount: string,
  volume: string,
  price: string,
  fee: string,
  date: string,
  fundCode: string,
  type: string
}

type JsonData = {
  id: string,
  amount: string,
  volume: string,
  price: string,
  fee: string,
  date: string,
  type: string,
  fundCode: string,
  fundName: string,
  planID: string,
  planName: string
}

function csvToArray(str: string) {
  const lines = str.split('\n');
  const headers = lines[0].slice(0,-1).split(',');

  let objects: any[] = [];
  lines.forEach((row, i) => {
    if (i === 0) return;

    const o: any = {};
    row.slice(0,-1).split(',').forEach((v, j) => o[headers[j]] = v);

    objects.push(o);
  });

  return objects;
}

async function convertAndSave (
  data: CsvData[] | JsonData[], 
  planID: string, 
  progress: Function, 
  showNotification: Function
  ) {
  const types = Object.values(TransactionType);

  const plan = await DataStore.query(Plan, planID);

  for (let i = 0; i< data.length; i++) {
    if (!types.includes(data[i].type as TransactionType)) {
      console.error(`This record's transaction type is not valid`);
      continue;
    }
    
    try {
      const fundCode = data[i].fundCode.padStart(6, '0');
      const fund = fundInfo.filter(f => f.code === fundCode)[0];
      const newTransaction = new Transaction({ 
      plan: plan,
      fundCode: fundCode,
      fund: fund,
      amount: data[i].amount,
      volume: data[i].volume,
      price: data[i].price,
      fee: data[i].fee,
      type: data[i].type,
      date: toCSTDateISOString(new Date(data[i].date.substring(0,10)))
    });

      await DataStore.save(newTransaction);
      progress(10 + 80 * i / data.length);
    } catch (err) {
      console.error(err);
      showNotification({isOpen: true, severity: "error", message: '导入文件格式不兼容！'});
      progress(0);
      return;
    }
  }
}

async function exportTransaction(type: string, filter: string[]) {

  const detailedTransactions = (await DataStore.query(Transaction));
  let filteredTransactions;
  console.log(filter)
  if (filter[0] === 'byPlan') {
    filteredTransactions = detailedTransactions.filter(t => t.plan!.name === filter[1]);
  } else {
    filteredTransactions = detailedTransactions;
  }
  console.log(filteredTransactions)
  const transactions: JsonData[] = [];

  for (const transaction of filteredTransactions) {
    transactions.push({
      id: transaction.id,
      amount: transaction.amount,
      volume: transaction.volume,
      price: transaction.price,
      fee: transaction.fee,
      date: transaction.date,
      type: transaction.type,
      fundCode: transaction.fundCode,
      fundName: transaction.fund.fullName,
      planID: transaction.plan!.id,
      planName: transaction.plan!.name
    })
  }

  if (type === 'json') {
    const jsonData = JSON.stringify(transactions, null, "\t");
    download(jsonData, 'transactions.json', 'text/plain');
  } else if (type === 'csv') {
    const csvData = convertToCSV(transactions);
    download(csvData, 'transactions.csv', 'text/plain');
  }

}

function download(content: string, fileName: string, contentType: string) {
  var a = document.createElement("a");
  var file = new Blob([content], {type: contentType});
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
}

function convertToCSV(arr: any[]) {
  const array = [Object.keys(arr[0])].concat(arr);

  return array.map(it => {
    return Object.values(it).toString();
  }).join('\n');
}

function convertFromText(text:string) {
  let formData: any = {
    amount: '0',
    volume: '0',
    price: '0',
    fee: '0',
    fund: null,
    type: '',
    date: new Date()
  }
  const funds = fundInfo as FundInfo[];

  text = text.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
  text = text.replace(/\s/g, '');
  console.info(text);

  try {
    if (text.includes('买入成功')) {
      formData.type = TransactionType.Buying;
      const name = text.split('买入产品')[1].split('买入金额')[0];
      formData.fund = funds.filter(f => f.fullName.includes(name))[0];
      const amount = text.split('买入金额')[1].split('元')[0];
      formData.amount = amount.substring(0, amount.length - 2) + '.' + amount.substring(amount.length - 2);
      const volume = text.split('确认份额')[1].split('份')[0];
      formData.volume = volume.substring(0, volume.length - 2) + '.' + volume.substring(volume.length - 2);
      const price = text.split('确认净值')[1].split('手续费')[0];
      formData.price = price.substring(0, price.length - 4) + '.' + price.substring(price.length - 4);
      const fee = text.split('手续费')[1].split('元')[0];
      formData.fee = fee.substring(0, fee.length - 2) + '.' + fee.substring(fee.length - 2);
      formData.date = new Date(text.split('确认时间')[1].split('订单号')[0]);
    } else if (text.includes('卖出成功')) {
      formData.type = TransactionType.Selling;
      const name = text.split('卖出产品')[1].split('卖出份额')[0];
      formData.fund = funds.filter(f => f.fullName.includes(name))[0];
      const volume = text.split('确认份额')[1].split('份')[0];
      formData.volume = volume.substring(0, volume.length - 2) + '.' + volume.substring(volume.length - 2);
      const price = text.split('确认净值')[1].split('手续费')[0];
      formData.price = price.substring(0, price.length - 4) + '.' + price.substring(price.length - 4);
      const fee = text.split('手续费')[1].split('元')[0].replace(/[^0-9]/g, '');
      formData.fee = fee.substring(0, fee.length - 2) + '.' + fee.substring(fee.length - 2);
      formData.date = new Date(text.split('卖出时间')[1].substring(0,10));
      const amount = parseFloat(formData.price) * parseFloat(formData.volume) - parseFloat(formData.fee);
      formData.amount = amount.toFixed(4);
    } else if (text.includes('现金分红')) {
      formData.type = TransactionType.CashDividend;
      const name = text.split('产品名称')[1].split('分红类型')[0];
      formData.fund = funds.filter(f => f.fullName.includes(name))[0];
      const amount = text.split('分红金额')[1].split('元')[0];
      formData.amount = amount.substring(0, amount.length - 2) + '.' + amount.substring(amount.length - 2);
      formData.volume = '0';
      formData.price = '0';
      formData.fee = '0';
      formData.date = new Date(text.split('时间')[1].substring(0,10));
    } else if (text.includes('红利再投资')) {
      formData.type = TransactionType.DividendReinvestment;
      const name = text.split('产品名称')[1].split('分红类型')[0];
      formData.fund = funds.filter(f => f.fullName.includes(name))[0];
      formData.amount = '0';
      const volume = text.split('分红数量')[1].split('份')[0];
      formData.volume = volume.substring(0, volume.length - 2) + '.' + volume.substring(volume.length - 2);
      formData.price = '0';
      formData.fee = '0';
      formData.date = new Date(text.split('时间')[1].substring(0,10));
    } else {
      formData = undefined;
    }
  } catch (error) {
    console.error(error);
    formData = undefined;
  }

  return formData;
}


export { convertFromText, importTransactions, exportTransaction }