function showCurrency(number: string | undefined) {
  if (!number) return '';
  return parseFloat(number).toLocaleString(
    'zh', {
      style: 'currency',
      currency: 'cny',
    }
  )
}

export {showCurrency}