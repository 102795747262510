import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from '@aws-amplify/api';
import { GetPriceByFundCodeAndDateQuery } from "API";
import { FundPrice } from "models";
import { getPriceByFundCodeAndDate } from "graphql/queries";

async function getPriceByFundCodeAndDateRange(
  fundCode: string,
  startTime: string,
  endTime: string,
  timeout = 3000) {
  const result = await API.graphql(
    graphqlOperation(
      getPriceByFundCodeAndDate,
      {
        fundCode: fundCode,
        startDate: startTime,
        endDate: endTime,
        timeout: timeout
      }
    )
    ) as GraphQLResult<GetPriceByFundCodeAndDateQuery>;

    const prices = result.data?.getPriceByFundCodeAndDate as FundPrice[];
    return prices
}

export { getPriceByFundCodeAndDateRange }