import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, Grid, ListItem, ListItemButton, Typography } from "@mui/material"
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { PlanFund } from "models"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { showCurrency } from "utils/currency";

interface FundItemProps {
  fund: PlanFund
}

function FundItem({ fund }: FundItemProps) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItemButton>
        <Grid container onClick={() => navigate('/dashboard/fund/'+ fund.id)}>
          <Grid item xs={10}>
            <Typography gutterBottom variant="body2">
              {fund.fundCode} {fund.fund.fullName}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {fund.daySummary.dayRate[0] === '-' ? (
                <Typography gutterBottom 
                  variant="body2" 
                  justifyContent="flex-end"
                  display="flex" 
                  alignItems="center"
                >
                  <SouthIcon color='success' fontSize='small'/>
                  <span style={{color: 'green'}}>{fund.daySummary.dayRate}%</span>
                </Typography>
            ) : (
                <Typography gutterBottom 
                  variant="body2" 
                  justifyContent="flex-end"
                  display="flex" 
                  alignItems="center"
                >
                  <NorthIcon color='error' fontSize='small'/>
                  <span style={{color: 'red'}}>{fund.daySummary.dayRate}%</span>
                </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="text.secondary">
              净值：{showCurrency(fund.daySummary.value)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography gutterBottom variant="body2" color="text.secondary">
              收益：{fund.daySummary.floatProfit[0] === '-' ? (
                <span style={{color: 'green'}}>{showCurrency(fund.daySummary.floatProfit)}</span>
              ) : (
                <span style={{color: 'red'}}>{showCurrency(fund.daySummary.floatProfit)}</span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="text.secondary" align="right">
              收益率：{fund.daySummary.floatRate[0] === '-' ? (
                <span style={{color: 'green'}}>{fund.daySummary.floatRate}%</span>
              ) : (
                <span style={{color: 'red'}}>{fund.daySummary.floatRate}%</span>
              )}
            </Typography>
          </Grid>
        </Grid>
        {open ? (
          <div onClick={()=>{setOpen(false)}}>
            <ExpandLess />
          </div>
        ) : (
          <div onClick={()=>{
            setOpen(true);
            setTimeout(()=>{setOpen(false)}, 20000);
          }}>
            <ExpandMore />
          </div>
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ListItem>
          <Grid container>
            <Grid item xs={4}>
              <Typography gutterBottom variant="body2" color="text.secondary">
                总成本：{showCurrency(fund.daySummary.totalCost)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography gutterBottom variant="body2" color="text.secondary">
                平均成本：{fund.daySummary.averageCost}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography gutterBottom variant="body2" color="text.secondary">
                当日价格：{fund.daySummary.price}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="text.secondary">
                持仓份额：{fund.daySummary.totalVolume}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography gutterBottom variant="body2" color="text.secondary">
                总收益：{fund.daySummary.totalProfit[0] === '-' ? (
                  <span style={{color: 'green'}}>{showCurrency(fund.daySummary.totalProfit)}</span>
                ) : (
                  <span style={{color: 'red'}}>{showCurrency(fund.daySummary.totalProfit)}</span>
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="text.secondary">
                总收益率：{fund.daySummary.totalRate[0] === '-' ? (
                  <span style={{color: 'green'}}>{fund.daySummary.totalRate}%</span>
                ) : (
                  <span style={{color: 'red'}}>{fund.daySummary.totalRate}%</span>
                )}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      </Collapse>
    </>
  )
}

export default FundItem
