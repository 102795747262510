import { DataStore } from "aws-amplify";
import { TransactionType } from "constants/TransactionType";
import { PlanFund } from "models";
import { Transaction } from "models";
import { updateUserData } from "./core";
import { updatePlan } from "./plan";
import { createPlanFundIfNotExist, updatePlanFundFromTransactions } from "./planFund";

async function updateDataAfterTransactionMutation(transaction: Transaction, setProgress: Function) {
  setProgress(0);

  const planFund = await createPlanFundIfNotExist(transaction);
  setProgress(10);

  await updatePlanFundFromTransactions(planFund, true, transaction.date);
  setProgress(50);

  await updatePlan(transaction.plan!, true, transaction.date);
  setProgress(80);

  await updateUserData(true, transaction.date);
  setProgress(100);
}

async function validateTransaction(transaction: Transaction) {
  if (transaction.type === TransactionType.Buying) {
    const validAmount = parseFloat(transaction.price) 
      * parseFloat(transaction.volume) 
      + parseFloat(transaction.fee);
    if (Math.abs(validAmount - parseFloat(transaction.amount)) > 0.02) {
      return '数据错误！请检查输入的数据。'
    }
  } else if (transaction.type === TransactionType.Selling) {
    const validAmount = parseFloat(transaction.price) 
      * parseFloat(transaction.volume) 
      - parseFloat(transaction.fee);
    if (Math.abs(validAmount - parseFloat(transaction.amount)) > 0.02) {
      return '数据错误！请检查输入的数据。'
    }

    const fundSum = (await DataStore.query(
      PlanFund,
      (f) => f.fundCode('eq', transaction.fundCode)
    )).filter(f => f.plan!.id === transaction.plan!.id)[0];

    if (!fundSum) {
      return `${transaction.plan?.name}未购入基金"${transaction.fundCode}"！请检查输入的基金。`
    }
    if (Number(transaction.volume) > Number(fundSum.daySummary.totalVolume)) {
      return `基金"${transaction.fundCode}"剩余份额不足！`
    }
  } else if (transaction.type === TransactionType.CashDividend) {
    if (parseFloat(transaction.amount) <= 0
      || parseFloat(transaction.volume) != 0
      || parseFloat(transaction.price) != 0
      || parseFloat(transaction.fee) != 0
    ) {
      return '金额不能为负！';
    }

    const fundSum = (await DataStore.query(
      PlanFund,
      (f) => f.fundCode('eq', transaction.fundCode)
    )).filter(f => f.plan!.id === transaction.plan!.id)[0];

    if (!fundSum) {
      return `${transaction.plan?.name}未购入基金"${transaction.fundCode}"！请检查输入的基金。`
    }
  } else {
    if (parseFloat(transaction.volume) <= 0
      || parseFloat(transaction.amount) != 0
      || parseFloat(transaction.price) != 0
      || parseFloat(transaction.fee) != 0
    ) {
      return '份额不能为负！';
    }

    const fundSum = (await DataStore.query(
      PlanFund,
      (f) => f.fundCode('eq', transaction.fundCode)
    )).filter(f => f.plan!.id === transaction.plan!.id)[0];

    if (!fundSum) {
      return `${transaction.plan?.name}未购入基金"${transaction.fundCode}"！请检查输入的基金。`
    }
  }

  return 'ValidData';
}

export { updateDataAfterTransactionMutation, validateTransaction }