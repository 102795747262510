import { IndexValue } from 'models';
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from 'react'
import { Button, CircularProgress, Paper, Stack, useTheme } from '@mui/material';
import { toCSTDateISOString } from 'utils/time';

interface LineChartProps{
  historyValues: (IndexValue | null)[]
}

type Data = [number, number]

function LineChart({ historyValues }: LineChartProps) {
  const [lineChartOptions, setLineChartOptions] = useState<any>();
  const [stockChartOptions, setStockChartOptions] = useState<any>();
  const [showStockChart, setShowStockChart] = useState(false);

  const makeLineChartDataAndSetOption = (month: number) => {
    if (historyValues.length === 0) return;
    let valuesForSort = [... historyValues];

    let values = valuesForSort.sort((a, b) => {
      return a!.date > b!.date ? 1 : -1
    });

    let d = new Date();
    d.setMonth(d.getMonth() - month);
    const startDate = month === 0 ? undefined : toCSTDateISOString(d);

    if (startDate) {
      values = values.filter(v => v!.date >= startDate)
    }

    let valueData: Data[] = [];
    let buyData: Data[] = [];
    let sellData: Data[] = []

    for (const value of values) {
      const dateInMs = (new Date(value!.date.slice(0,10)+'T00:00+0800')).getTime();
      const indexValue = parseFloat(value!.index);
      valueData.push([ dateInMs, indexValue ]);

      const active = parseInt(value!.active??'0');
      if (active > 0) {
        buyData.push([ dateInMs, indexValue ]);
      } else if (active < 0) {
        sellData.push([ dateInMs, indexValue ]);
      }
    }

    setLineChartOptions({
      time:{
        useUTC: false,
        timezone: 'shanghai'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ' '
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        gridLineColor: 'rgb(42, 51, 73)',
        gridLineDashStyle: 'LongDash',
        title: {
          text: ' '
        },
        labels: {
          // formatter: function(): string {
          //   return Highcharts.numberFormat(this.value/1, 0) + " %";
          // }
        }
      },
      plotOptions: {
        area: {
          lineWidth: 1,
          threshold: null
        }
      },
      series: [{
        name: '净值',
        data: valueData,
        lineWidth: 1,
        tooltip: {
          valueDecimals: 4
        },
        marker: {
          enabled: false
        }
      }, {
        name: '买入',
        data: buyData,
        lineWidth: 0,
        color: 'red',
        marker: {
          symbol: 'triangle',
          enabled: true,
          radius: 4
        },
        states: {
          hover: {
            lineWidthPlus: 0
          }
        },
        enableMouseTracking: false
      }, {
        name: '卖出',
        data: sellData,
        lineWidth: 0,
        color: 'green',
        enableMouseTracking: false,
        marker: {
          symbol: 'triangle-down',
          enabled: true,
          radius: 4
        },
        states: {
          hover: {
              lineWidthPlus: 0
          }
        }
      }]
    });

    setShowStockChart(false);
  }

  const makeStockChartDataAndSetOption = () => {
    if (historyValues.length === 0) return;
    let valuesForSort = [... historyValues];

    const values = valuesForSort.sort((a, b) => {
      return a!.date > b!.date ? 1 : -1
    });

    let newData: Data[] = [];

    for (const value of values) {
      newData.push([
        (new Date(value!.date.slice(0,10)+'T00:00+0800')).getTime(),
        parseFloat(value!.index)
      ]);
    }

    setStockChartOptions({
      rangeSelector: {
        selected: 1
      },
      time:{
        useUTC: false,
        timezone: 'shanghai'
      },
      scrollbar: {
        enabled: false
      },
      series: [{
        data: newData,
        tooltip: {
          valueDecimals: 4
        }
      }]
    });
  }

  // const netValueClickedHandler = (month: number) => {
  //   let d = new Date();
  //   d.setMonth(d.getMonth() - month);
  //   const startDate = month === 0 ? undefined : toCSTDateISOString(d);
  //   makeDataAndSetOption(startDate);
  // }

  useEffect(() => {
    makeLineChartDataAndSetOption(3);
    makeStockChartDataAndSetOption();
  }, [historyValues]);


  return (
    <>
      {lineChartOptions ? (
        <Paper sx={{marginTop: 1}}>
          <Stack direction="row" spacing={1} sx={{p: 1}}>
            <Button size="small"
              onClick={() => makeLineChartDataAndSetOption(3)}
            >
              三月净值
            </Button>
            <Button size="small"
              onClick={() => makeLineChartDataAndSetOption(6)}
            >
              六月净值
            </Button>
            <Button size="small"
              onClick={() => makeLineChartDataAndSetOption(12)}
            >
              一年净值
            </Button>
            <Button size="small"
              onClick={() => makeLineChartDataAndSetOption(0)}
            >
              全部净值
            </Button>
            <Button size="small"
              onClick={() => setShowStockChart(true)}
            >
              专业视图
            </Button>
          </Stack>
          {showStockChart &&
            <HighchartsReact
              highcharts={Highcharts}
              constructorType={"stockChart"}
              options={stockChartOptions}
              allowChartUpdate = { true }
              oneToOne={true}
            />
          }
          {!showStockChart &&
            <HighchartsReact
              highcharts={Highcharts}
              options={lineChartOptions}
            />
          }
        </Paper>
      ) : (
        <CircularProgress />
      )}
    </>
  )
}

export default LineChart
