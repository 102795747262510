/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPriceByFundCodeAndDate = /* GraphQL */ `
  query GetPriceByFundCodeAndDate(
    $fundCode: String!
    $startDate: AWSDate!
    $endDate: AWSDate!
    $timeout: Float!
  ) {
    getPriceByFundCodeAndDate(
      fundCode: $fundCode
      startDate: $startDate
      endDate: $endDate
      timeout: $timeout
    ) {
      date
      value
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      owner
      amount
      volume
      price
      fee
      date
      fundCode
      fund {
        code
        shortName
        fullName
        type
      }
      planID
      plan {
        id
        owner
        name
        description
        funds {
          items {
            id
            fundCode
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
          startedAt
        }
        transactions {
          items {
            id
            owner
            amount
            volume
            price
            fee
            date
            fundCode
            planID
            type
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        daySummary {
          date
          totalVolume
          totalCost
          averageCost
          price
          value
          floatProfit
          floatRate
          sellProfit
          outAmount
          totalProfit
          totalRate
          dayRate
        }
        historyValues {
          date
          index
          value
          active
        }
        targets {
          fundCode
          targetPrice
          targetCost
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        amount
        volume
        price
        fee
        date
        fundCode
        fund {
          code
          shortName
          fullName
          type
        }
        planID
        plan {
          id
          owner
          name
          description
          funds {
            nextToken
            startedAt
          }
          transactions {
            nextToken
            startedAt
          }
          daySummary {
            date
            totalVolume
            totalCost
            averageCost
            price
            value
            floatProfit
            floatRate
            sellProfit
            outAmount
            totalProfit
            totalRate
            dayRate
          }
          historyValues {
            date
            index
            value
            active
          }
          targets {
            fundCode
            targetPrice
            targetCost
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTransactions = /* GraphQL */ `
  query SyncTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        amount
        volume
        price
        fee
        date
        fundCode
        fund {
          code
          shortName
          fullName
          type
        }
        planID
        plan {
          id
          owner
          name
          description
          funds {
            nextToken
            startedAt
          }
          transactions {
            nextToken
            startedAt
          }
          daySummary {
            date
            totalVolume
            totalCost
            averageCost
            price
            value
            floatProfit
            floatRate
            sellProfit
            outAmount
            totalProfit
            totalRate
            dayRate
          }
          historyValues {
            date
            index
            value
            active
          }
          targets {
            fundCode
            targetPrice
            targetCost
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const transactionsByOwner = /* GraphQL */ `
  query TransactionsByOwner(
    $owner: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByOwner(
      owner: $owner
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        amount
        volume
        price
        fee
        date
        fundCode
        fund {
          code
          shortName
          fullName
          type
        }
        planID
        plan {
          id
          owner
          name
          description
          funds {
            nextToken
            startedAt
          }
          transactions {
            nextToken
            startedAt
          }
          daySummary {
            date
            totalVolume
            totalCost
            averageCost
            price
            value
            floatProfit
            floatRate
            sellProfit
            outAmount
            totalProfit
            totalRate
            dayRate
          }
          historyValues {
            date
            index
            value
            active
          }
          targets {
            fundCode
            targetPrice
            targetCost
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const transactionsByPlanAndDate = /* GraphQL */ `
  query TransactionsByPlanAndDate(
    $planID: ID!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByPlanAndDate(
      planID: $planID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        amount
        volume
        price
        fee
        date
        fundCode
        fund {
          code
          shortName
          fullName
          type
        }
        planID
        plan {
          id
          owner
          name
          description
          funds {
            nextToken
            startedAt
          }
          transactions {
            nextToken
            startedAt
          }
          daySummary {
            date
            totalVolume
            totalCost
            averageCost
            price
            value
            floatProfit
            floatRate
            sellProfit
            outAmount
            totalProfit
            totalRate
            dayRate
          }
          historyValues {
            date
            index
            value
            active
          }
          targets {
            fundCode
            targetPrice
            targetCost
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const transactionsByPlanAndFundAndDate = /* GraphQL */ `
  query TransactionsByPlanAndFundAndDate(
    $planID: ID!
    $fundCodeDate: ModelTransactionByPlanByFundByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByPlanAndFundAndDate(
      planID: $planID
      fundCodeDate: $fundCodeDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        amount
        volume
        price
        fee
        date
        fundCode
        fund {
          code
          shortName
          fullName
          type
        }
        planID
        plan {
          id
          owner
          name
          description
          funds {
            nextToken
            startedAt
          }
          transactions {
            nextToken
            startedAt
          }
          daySummary {
            date
            totalVolume
            totalCost
            averageCost
            price
            value
            floatProfit
            floatRate
            sellProfit
            outAmount
            totalProfit
            totalRate
            dayRate
          }
          historyValues {
            date
            index
            value
            active
          }
          targets {
            fundCode
            targetPrice
            targetCost
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlanFund = /* GraphQL */ `
  query GetPlanFund($id: ID!) {
    getPlanFund(id: $id) {
      id
      fundCode
      fund {
        code
        shortName
        fullName
        type
      }
      planID
      plan {
        id
        owner
        name
        description
        funds {
          items {
            id
            fundCode
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
          startedAt
        }
        transactions {
          items {
            id
            owner
            amount
            volume
            price
            fee
            date
            fundCode
            planID
            type
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        daySummary {
          date
          totalVolume
          totalCost
          averageCost
          price
          value
          floatProfit
          floatRate
          sellProfit
          outAmount
          totalProfit
          totalRate
          dayRate
        }
        historyValues {
          date
          index
          value
          active
        }
        targets {
          fundCode
          targetPrice
          targetCost
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      daySummary {
        date
        totalVolume
        totalCost
        averageCost
        price
        value
        floatProfit
        floatRate
        sellProfit
        outAmount
        totalProfit
        totalRate
        dayRate
      }
      historyValues {
        date
        index
        value
        active
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listPlanFunds = /* GraphQL */ `
  query ListPlanFunds(
    $filter: ModelPlanFundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlanFunds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fundCode
        fund {
          code
          shortName
          fullName
          type
        }
        planID
        plan {
          id
          owner
          name
          description
          funds {
            nextToken
            startedAt
          }
          transactions {
            nextToken
            startedAt
          }
          daySummary {
            date
            totalVolume
            totalCost
            averageCost
            price
            value
            floatProfit
            floatRate
            sellProfit
            outAmount
            totalProfit
            totalRate
            dayRate
          }
          historyValues {
            date
            index
            value
            active
          }
          targets {
            fundCode
            targetPrice
            targetCost
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        daySummary {
          date
          totalVolume
          totalCost
          averageCost
          price
          value
          floatProfit
          floatRate
          sellProfit
          outAmount
          totalProfit
          totalRate
          dayRate
        }
        historyValues {
          date
          index
          value
          active
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPlanFunds = /* GraphQL */ `
  query SyncPlanFunds(
    $filter: ModelPlanFundFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPlanFunds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        fundCode
        fund {
          code
          shortName
          fullName
          type
        }
        planID
        plan {
          id
          owner
          name
          description
          funds {
            nextToken
            startedAt
          }
          transactions {
            nextToken
            startedAt
          }
          daySummary {
            date
            totalVolume
            totalCost
            averageCost
            price
            value
            floatProfit
            floatRate
            sellProfit
            outAmount
            totalProfit
            totalRate
            dayRate
          }
          historyValues {
            date
            index
            value
            active
          }
          targets {
            fundCode
            targetPrice
            targetCost
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        daySummary {
          date
          totalVolume
          totalCost
          averageCost
          price
          value
          floatProfit
          floatRate
          sellProfit
          outAmount
          totalProfit
          totalRate
          dayRate
        }
        historyValues {
          date
          index
          value
          active
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const planFundsByPlanAndFund = /* GraphQL */ `
  query PlanFundsByPlanAndFund(
    $planID: ID!
    $fundCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlanFundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    planFundsByPlanAndFund(
      planID: $planID
      fundCode: $fundCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fundCode
        fund {
          code
          shortName
          fullName
          type
        }
        planID
        plan {
          id
          owner
          name
          description
          funds {
            nextToken
            startedAt
          }
          transactions {
            nextToken
            startedAt
          }
          daySummary {
            date
            totalVolume
            totalCost
            averageCost
            price
            value
            floatProfit
            floatRate
            sellProfit
            outAmount
            totalProfit
            totalRate
            dayRate
          }
          historyValues {
            date
            index
            value
            active
          }
          targets {
            fundCode
            targetPrice
            targetCost
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        daySummary {
          date
          totalVolume
          totalCost
          averageCost
          price
          value
          floatProfit
          floatRate
          sellProfit
          outAmount
          totalProfit
          totalRate
          dayRate
        }
        historyValues {
          date
          index
          value
          active
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlan = /* GraphQL */ `
  query GetPlan($id: ID!) {
    getPlan(id: $id) {
      id
      owner
      name
      description
      funds {
        items {
          id
          fundCode
          fund {
            code
            shortName
            fullName
            type
          }
          planID
          plan {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          daySummary {
            date
            totalVolume
            totalCost
            averageCost
            price
            value
            floatProfit
            floatRate
            sellProfit
            outAmount
            totalProfit
            totalRate
            dayRate
          }
          historyValues {
            date
            index
            value
            active
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      transactions {
        items {
          id
          owner
          amount
          volume
          price
          fee
          date
          fundCode
          fund {
            code
            shortName
            fullName
            type
          }
          planID
          plan {
            id
            owner
            name
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      daySummary {
        date
        totalVolume
        totalCost
        averageCost
        price
        value
        floatProfit
        floatRate
        sellProfit
        outAmount
        totalProfit
        totalRate
        dayRate
      }
      historyValues {
        date
        index
        value
        active
      }
      targets {
        fundCode
        targetPrice
        targetCost
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPlans = /* GraphQL */ `
  query ListPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        description
        funds {
          items {
            id
            fundCode
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
          startedAt
        }
        transactions {
          items {
            id
            owner
            amount
            volume
            price
            fee
            date
            fundCode
            planID
            type
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        daySummary {
          date
          totalVolume
          totalCost
          averageCost
          price
          value
          floatProfit
          floatRate
          sellProfit
          outAmount
          totalProfit
          totalRate
          dayRate
        }
        historyValues {
          date
          index
          value
          active
        }
        targets {
          fundCode
          targetPrice
          targetCost
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPlans = /* GraphQL */ `
  query SyncPlans(
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPlans(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        description
        funds {
          items {
            id
            fundCode
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
          startedAt
        }
        transactions {
          items {
            id
            owner
            amount
            volume
            price
            fee
            date
            fundCode
            planID
            type
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        daySummary {
          date
          totalVolume
          totalCost
          averageCost
          price
          value
          floatProfit
          floatRate
          sellProfit
          outAmount
          totalProfit
          totalRate
          dayRate
        }
        historyValues {
          date
          index
          value
          active
        }
        targets {
          fundCode
          targetPrice
          targetCost
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const plansByOwner = /* GraphQL */ `
  query PlansByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    plansByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        description
        funds {
          items {
            id
            fundCode
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            owner
          }
          nextToken
          startedAt
        }
        transactions {
          items {
            id
            owner
            amount
            volume
            price
            fee
            date
            fundCode
            planID
            type
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        daySummary {
          date
          totalVolume
          totalCost
          averageCost
          price
          value
          floatProfit
          floatRate
          sellProfit
          outAmount
          totalProfit
          totalRate
          dayRate
        }
        historyValues {
          date
          index
          value
          active
        }
        targets {
          fundCode
          targetPrice
          targetCost
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserData = /* GraphQL */ `
  query GetUserData($id: ID!) {
    getUserData(id: $id) {
      id
      daySummary {
        date
        totalVolume
        totalCost
        averageCost
        price
        value
        floatProfit
        floatRate
        sellProfit
        outAmount
        totalProfit
        totalRate
        dayRate
      }
      historyValues {
        date
        index
        value
        active
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listUserData = /* GraphQL */ `
  query ListUserData(
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        daySummary {
          date
          totalVolume
          totalCost
          averageCost
          price
          value
          floatProfit
          floatRate
          sellProfit
          outAmount
          totalProfit
          totalRate
          dayRate
        }
        historyValues {
          date
          index
          value
          active
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserData = /* GraphQL */ `
  query SyncUserData(
    $filter: ModelUserDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        daySummary {
          date
          totalVolume
          totalCost
          averageCost
          price
          value
          floatProfit
          floatRate
          sellProfit
          outAmount
          totalProfit
          totalRate
          dayRate
        }
        historyValues {
          date
          index
          value
          active
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getConfiguration = /* GraphQL */ `
  query GetConfiguration($id: ID!) {
    getConfiguration(id: $id) {
      id
      owner
      fundNotification {
        isEnabled
        email
        plans
        metrics {
          name
          isUpper
          value
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listConfigurations = /* GraphQL */ `
  query ListConfigurations(
    $filter: ModelConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConfigurations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        fundNotification {
          isEnabled
          email
          plans
          metrics {
            name
            isUpper
            value
          }
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncConfigurations = /* GraphQL */ `
  query SyncConfigurations(
    $filter: ModelConfigurationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConfigurations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        fundNotification {
          isEnabled
          email
          plans
          metrics {
            name
            isUpper
            value
          }
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const configurationsByOwner = /* GraphQL */ `
  query ConfigurationsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelConfigurationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    configurationsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        fundNotification {
          isEnabled
          email
          plans
          metrics {
            name
            isUpper
            value
          }
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
