import { CircularProgress, Paper } from "@mui/material";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from "react"
import { Plan, PlanFund } from "models";

interface BarChartProps {
  items: (Plan | PlanFund | null)[]
}
function BarChart({items}: BarChartProps) {

  const makeData = () => {
    let newData = [];

    for (const item of items) {
      if (item && item.daySummary){
        const floatProfit = parseFloat(item.daySummary.floatProfit);
        if (!isNaN(floatProfit)) {

          let name = (item instanceof Plan)
            ? (item as Plan).name 
            : (item as PlanFund).fund.fullName;

          if (floatProfit > 0) {
            newData.push({
              name: name,
              data: [floatProfit, parseFloat(item.daySummary.totalCost)]
            });
          } else {
            newData.push({
              name: name,
              data: [0, parseFloat(item.daySummary.totalCost)]
            });
          }
        }
      }
    }
    return newData;
  }

  const [options, setOptions] = useState<any>();


  useEffect(() => {
    const newData = makeData();
    setOptions({
      chart: {
          type: 'bar',
      },
      credits: {
            enabled: false
        },
      title: {
          text: '收益成本对比',
      },
      xAxis: {
          categories: ['收益', '成本'],
          labels: {
          style: {
            color: 'rgb(158, 161, 176)',
         }
      },
      },
      yAxis: {
        gridLineColor: 'rgb(158, 161, 176)',
        gridLineDashStyle: 'LongDash',
        min: 0,
        title: {
            text: ''
        },
      },
      tooltip: {
          pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.2f}</b> ({point.percentage:.1f}%)<br/>',
          shared: true
      },
      plotOptions: {
          bar: {
              stacking: 'percent'
          },
          series: {
            borderWidth: 0,
          }
      },
      series: newData
    })
  },[items])


  return (
    <>
      {options ? (
        <Paper sx={{marginTop: 1}}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </Paper>
      ) : (
        <CircularProgress />
      )}
    </>
  )
}

export default BarChart
