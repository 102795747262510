import { Box } from '@mui/material';
import { DataStore } from '@aws-amplify/datastore';
import { TransactionList } from 'components';
import { Data } from 'components/Transaction/sort';
import { useCtx } from 'hooks/useCtx';
import { Transaction } from 'models';
import { useEffect, useState } from 'react';

function TransactionPage() {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [data, setData] = useState<Data[]>([]);
  const {setProgress} = useCtx();

  useEffect(() => {
    const sub = DataStore.observeQuery(Transaction
    ).subscribe(({ items }) => {
      setTransactions(items);
    });

    return () => {
      sub.unsubscribe();
    };
  }, [])

  useEffect(() => {
    let d: Data[] = []
    transactions.forEach((t) => {
      d.push({
        id: t.id,
        fundCode: t.fundCode,
        fundName: t.fund?.fullName??'',
        plan: t.plan?.name??'',
        type: t.type,
        amount: t.amount,
        volume: t.volume,
        price: t.price,
        fee: t.fee,
        date: t.date
      })
    });
    setData(d);
  }, [transactions])

  return (
    <Box sx={{m: 2}} display={{ xs: 'none', md: 'block' }}>
      <TransactionList data={data} />
    </Box>
  )
}

export default TransactionPage
