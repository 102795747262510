import { 
  Autocomplete, Backdrop, Box, Button, Card, CardActions, CardContent, 
  CircularProgress, 
  FormControl, Input, InputAdornment, InputLabel, MenuItem, 
  Modal, 
  Select, TextField 
} from '@mui/material';
import CurrencyYuanIcon from '@mui/icons-material/CurrencyYuan';
import { Container } from '@mui/system';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useCtx } from 'hooks/useCtx';
import { FundInfo, Plan, Transaction } from 'models';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import fundInfo from '../constants/fundInfo.json';
import { TransactionType } from '../constants/TransactionType';
import { DataStore } from '@aws-amplify/datastore';
import { toCSTDateISOString } from 'utils/time';
import { PlanFund } from 'models';
import { updateDataAfterTransactionMutation, validateTransaction } from 'services';


export type FormData = {
  amount: string,
  volume: string,
  price: string,
  fee: string,
  fund: FundInfo | null,
  plan: Plan,
  type: string,
  date: Date
}

interface TransactionFormProps {
  data?: FormData
} 

function TransactionForm({ data }: TransactionFormProps) {
  const params = useParams();
  const navigate = useNavigate();
  const {plans, showNotification, setProgress} = useCtx();
  const types = Object.values(TransactionType);
  const funds = fundInfo as FundInfo[];
  const [transaction, setTransaction] = useState<Transaction|undefined>();
  const [defaultFunds, setDefaultFunds] = useState(funds);
  const [formData, setFormData] = useState<FormData>(data ? data : {
    plan: {
      id: '',
      name: '',
      description: ''
    },
    fund: funds[0],
    type: types[0],
    amount: '',
    volume: '',
    price: '',
    fee: '',
    date: new Date()
  });
  const [disabledStatus, setDisabledStatus] = useState({
    amount: false,
    volume: false,
    price: false,
    fee: false
  })
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [toDeleteTransactionID, setToDeleteTransactionID] = useState('');

  const [backdropOpen, setBackdropOpen] = useState(false);

  const defaultProps = {
    options: defaultFunds,
    getOptionLabel: (option: FundInfo) => `${option.code} ${option.fullName}(${option.shortName})`,
  };

  useEffect(() => {
    if (plans.length > 0) setPlanAndFundAndDefaultFunds(plans[0].id, true);
  }, [plans]);

  useEffect(() => {
    const sub = DataStore.observeQuery(Transaction, (t) =>
      t.id('eq', params.transactionID??'')
    ).subscribe(({ items }) => {
      if (items.length > 0) {
        setTransaction(items[0]);

        setStatusByType(items[0].type);

        setFormData({
          amount: items[0].amount,
          volume: items[0].volume,
          price: items[0].price,
          fee: items[0].fee,
          fund: funds.filter(f => f.code === items[0].fundCode)[0],
          plan: plans.filter(p => p.id === items[0].plan?.id)[0],
          type: items[0].type,
          date: new Date(items[0].date.slice(0,10)),
        });
      }
    });

    return () => {
      sub.unsubscribe();
    };
  },[]);

  useEffect(() => {
    if (!data) return;
    setStatusByType(data.type);
    setFormData(data);
  }, [data])

  const setPlanAndFundAndDefaultFunds = async (planID: string, initial: boolean = false) => {
    let t: Transaction | undefined;
    if (params.transactionID) {
      t = await DataStore.query(Transaction, params.transactionID);
      if (initial) {
        planID = t!.plan!.id;
      }
    }
    
    let fs: FundInfo[] = [];

    const planFunds = (await DataStore.query(PlanFund))
      .filter(f => f.plan!.id === planID);

    for (const fund of planFunds) {
      fs.push(funds.filter(f => f.code === fund?.fundCode)[0]);
    }

    if (initial) {
      if (params.transactionID) {
        setFormData({
          amount: t!.amount,
          volume: t!.volume,
          price: t!.price,
          fee: t!.fee,
          plan: plans.filter(p => p.id === planID)[0],
          fund: funds.filter(f => f.code === t!.fundCode)[0],
          type: t!.type,
          date: new Date(t!.date.slice(0,10)),
        });
      } else {
        setFormData({
          ...formData,
          plan: plans.filter(p => p.id === planID)[0],
          fund: funds.filter(f => f.code === fs[0].code)[0]
        })
      }
    } else {
      if (params.transactionID) {
        setFormData({
          ...formData,
          plan: plans.filter(p => p.id === planID)[0],
        });
        if (!existsInDefaultFunds(formData.fund!.code, fs)) fs.push(formData.fund!);
      } else {
        if (fs.length > 0) {
          setFormData({
            ...formData,
            plan: plans.filter(p => p.id === planID)[0],
            fund: funds.filter(f => f.code === fs[0].code)[0],
          });
        } else {
          setFormData({
            ...formData,
            plan: plans.filter(p => p.id === planID)[0],
          });
        }
      }
    }

    if (fs.length > 0) setDefaultFunds(fs);
  }

  const existsInDefaultFunds = (fundCode: string, fs: FundInfo[]) => {
    for (const f of fs) {
      if (f.code === fundCode) return true;
    }
    return false;
  }

  const setStatusByType = (type: string) => {
    if (type === TransactionType.Buying) {
      setDisabledStatus({
        amount: false,
        volume: false,
        price: false,
        fee: false
      });
      setFormData({
        ...formData,
        type: type
      });  
    } else if (type === TransactionType.Selling) {
      setDisabledStatus({
        amount: true,
        volume: false,
        price: false,
        fee: false
      });
      setFormData({
        ...formData,
        type: type
      }); 
    } else if (type === TransactionType.CashDividend) {
      setDisabledStatus({
        amount: false,
        volume: true,
        price: true,
        fee: true
      });
      setFormData({
        ...formData,
        volume: '0',
        price: '0',
        fee: '0',
        type: type
      });
    } else {
      setDisabledStatus({
        amount: true,
        volume: false,
        price: true,
        fee: true
      });
      setFormData({
        ...formData,
        amount: '0',
        price: '0',
        fee: '0',
        type: type
      });
    }
  }

  const setInput = (key: string)=> (event: any) => {
    if (key === 'plan') {
      setPlanAndFundAndDefaultFunds(event.target.value);
    } else if (key === 'type') {
      const type = event.target.value;
      setStatusByType(type);
    } else {
      const newFormData = { ...formData, [key]: event.target.value };
      if (formData.type === TransactionType.Selling) {
        const amount = parseFloat(newFormData.price) * parseFloat(newFormData.volume) - parseFloat(newFormData.fee);
        setFormData({
          ...newFormData,
          amount: amount.toFixed(4)
        })
      } else {
        setFormData(newFormData);
      }
    }
  }

  const setFund = (event: any, newValue: FundInfo | null) => {
    setFormData({
      ...formData, 
      fund: newValue
    });
  }

  const dateChangedHandler = (date: Date | null) => {
    if (date) {
      setFormData({
        ...formData,
        date
      });
    }
  }

  const saveClickHandler = async () => {
    try {
      let toSaveTransaction: Transaction;
      let savedTransaction: Transaction;
      if (transaction) {
        toSaveTransaction = Transaction.copyOf(transaction!, (draft) => {
          draft.plan = formData.plan;
          draft.fundCode = formData.fund!.code;
          draft.fund = formData.fund!;
          draft.amount = formData.amount;
          draft.volume = formData.volume;
          draft.price = formData.price;
          draft.fee = formData.fee??'0';
          draft.type = formData.type;
          draft.date = toCSTDateISOString(formData.date);
        });
      } else {
        toSaveTransaction = new Transaction({
          plan: formData.plan,
          fundCode: formData.fund!.code,
          fund: formData.fund!,
          amount: formData.amount,
          volume: formData.volume,
          price: formData.price,
          fee: formData.fee??'0',
          type: formData.type,
          date: toCSTDateISOString(formData.date),
        });
      }

      const validateResult = await validateTransaction(toSaveTransaction);
      if (validateResult !== 'ValidData') {
        showNotification({isOpen: true, severity: 'error', message: validateResult});
        return;
      }

      savedTransaction = await DataStore.save(toSaveTransaction);

      setBackdropOpen(true);
      await updateDataAfterTransactionMutation(savedTransaction, setProgress);
      setBackdropOpen(false);

      showNotification({isOpen: true, severity: 'success', message: '交易记录已保存！'});

      navigate(-1);
    } catch (err) {
      console.error(err);
      showNotification({isOpen: true, severity: 'error', message: '保存失败，请联系管理员！'});
    }
  }

  const deleteClickHandler = () => {
    setToDeleteTransactionID(params.transactionID!);
    setConfirmOpen(true);
  }

  const deleteConfirmHandler = async () => {
    try{
      const toDeleteTransaction = await DataStore.query(Transaction, toDeleteTransactionID);
      await DataStore.delete(toDeleteTransaction!);
      updateDataAfterTransactionMutation(toDeleteTransaction!, setProgress);
      setConfirmOpen(false);
      showNotification({isOpen: true, severity: 'success', message: '交易记录已删除！'});
    
    } catch (err) {
      console.error(err);
      showNotification({isOpen: true, severity: 'error', message: `删除失败，请联系管理员. ${err}`});
    }
    navigate(-1);
  }

  const cancelClickHandler = () => {
    navigate(-1);
  }
  
  return (
    <>
      <Container maxWidth="sm" sx={{mt: 2}}>
        <Card>
          <CardContent>
            <FormControl fullWidth sx={{ my: 1 }} variant="standard">
              <InputLabel>组合名称</InputLabel>
              <Select
                value={formData.plan?.id}
                onChange={setInput('plan')}
              >
                {plans.map((plan, index)=>(
                  <MenuItem key={index} value={plan.id}>{plan.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Autocomplete
              {...defaultProps}
              value={formData.fund}
              onChange={setFund}
              renderInput={(params) => (
                <TextField {...params} label="基金名称" variant="standard" />
              )}
              onInput={() => setDefaultFunds(funds)}
            />
            <FormControl fullWidth sx={{ my: 1 }} variant="standard">
              <InputLabel >交易类型</InputLabel>
              <Select
                value={formData.type}
                onChange={setInput('type')}
              >
                {types.map((type, index)=>(
                  <MenuItem key={index} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ my: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">成交金额</InputLabel>
              <Input
                // type="number"
                disabled={disabledStatus.amount}
                value={formData.amount}
                onChange={setInput('amount')}
                startAdornment={<InputAdornment position="start"><CurrencyYuanIcon /></InputAdornment>}
              />
            </FormControl>
            <FormControl fullWidth sx={{ my: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">成交份额</InputLabel>
              <Input
                // type="number"
                disabled={disabledStatus.volume}
                value={formData.volume}
                onChange={setInput('volume')}
                startAdornment={<InputAdornment position="start"><CurrencyYuanIcon /></InputAdornment>}
              />
            </FormControl>
            <FormControl fullWidth sx={{ my: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">成交价格</InputLabel>
              <Input
                // type="number"
                disabled={disabledStatus.price}
                value={formData.price}
                onChange={setInput('price')}
                startAdornment={<InputAdornment position="start"><CurrencyYuanIcon /></InputAdornment>}
              />
            </FormControl>
            <FormControl fullWidth sx={{ my: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">手续费</InputLabel>
              <Input
                // type="number"
                disabled={disabledStatus.fee}
                value={formData.fee}
                onChange={setInput('fee')}
                startAdornment={<InputAdornment position="start"><CurrencyYuanIcon /></InputAdornment>}
              />
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                label="Date"
                value={formData.date}
                inputFormat="yyyy-MM-dd"
                onChange={(newValue) => {
                  dateChangedHandler(newValue);
                }}
                renderInput={(params) => <TextField {...params} fullWidth  sx={{ my: 1 }} variant="standard"/>}
              />
            </LocalizationProvider>
          </CardContent>
          <CardActions>
            <Button
              sx = {{ marginLeft: 'auto'}}
              onClick={saveClickHandler}
              size='large'
            >
              保存
            </Button>
            {params.transactionID? (
              <Button
                color="error"
                sx = {{ marginLeft: 'auto'}}
                onClick={deleteClickHandler}
                size='large'
              >
                删除
              </Button>
            ) : (
              <></>
            )}
            <Button 
              color="success"
              onClick={cancelClickHandler}
              size='large'
            >
              返回
            </Button>
          </CardActions>
        </Card>
      </Container>
      <Modal
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="modal-title">删除确认</h2>
          <p id="modal-description">
            确定删除该交易记录?
          </p>
          <Button color='error' onClick={deleteConfirmHandler}>删除</Button>
          <Button onClick={()=>setConfirmOpen(false)}>取消</Button>
        </Box>
      </Modal>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress />
      </Backdrop>
    </>
  )
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default TransactionForm
