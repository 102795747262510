import { Divider, List, ListItemButton, Paper, Typography } from '@mui/material'
import { Plan } from 'models'
import { useNavigate } from 'react-router-dom'
import PlanItem from './PlanItem'

interface PlanListProps {
  plans: Plan[]
}

function PlanList({ plans }: PlanListProps) {
  const navigate = useNavigate();
  return (
    <Paper
      sx={{
          // display: 'flex',
          // flexDirection: 'row',
          // justifyContent: 'center',
          // flexWrap: 'wrap',
          marginTop: 1
      }}
    >
      <List component="nav">
        {plans.map((plan, index) => {
          return (
            <div key={plan.id}>
              <ListItemButton>
                <PlanItem plan={plan}/>
              </ListItemButton>
              <Divider/>
            </div>
          )
        })}
        <ListItemButton onClick={() => navigate('/plans/new')}
          sx={{ cursor: 'pointer', display:'flex', justifyContent:'center' }}
        >
          <Typography variant="subtitle1" component="div">
            添加新计划
          </Typography>        
        </ListItemButton>
      </List>      
    </Paper>
  )
}

export default PlanList
