import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Button, CircularProgress, Collapse, Divider, Grid, LinearProgress, ListItem, Paper, Typography } from "@mui/material"
import { FundInfo, FundPrice, FundTarget, PlanFund } from "models"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom";
import { getPriceByFundCodeAndDateRange } from "services/core/fundPrice";
import { toLocalISOString } from "utils/time";
import fundInfo from '../../constants/fundInfo.json';
import TargetsStatus from "./TargetsStatus";

interface TargetBlockProps {
  planID: string,
  funds: PlanFund[],
  targets: FundTarget[]
}

export type TargetStatus = {
  fundCode: string,
  fundName: string,
  targetPrice: string,
  currentPrice: string,
  priceRate: number,
  targetCost: string,
  currentCost: string,
  costGap: number,
}

function TargetBlock({ planID, funds, targets }: TargetBlockProps) {
  const allFunds = fundInfo as FundInfo[];
  const [targetsStatus, setTargetsStatus] = useState<TargetStatus[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [allowRunning, setAllowRunning] = useState(true);
  const allowRunningRef = useRef<boolean>();
  allowRunningRef.current = allowRunning;
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setAllowRunning(false);
    setIsOpen(false);
    setIsLoading(true);
    setTargetsStatus([]);
  }, [planID])

  // Set states to terminate initTargetsStatus async function if plan is changed.
  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    if (allowRunning) {
      initTargetsStatus();
    }
    else {
      setTimeout(() => {setAllowRunning(true)}, 2000);
    }
  }, [allowRunning])

  const initTargetsStatus = async () => {
    let newTargetsStatus: TargetStatus[] = [];

    if (!targets || targets.length === 0) {
      setIsLoading(false);
      return;
    }
    
    for (const target of targets) {
      if (!allowRunningRef.current) return; // Terminate the function if plan is changed.

      const boughtFunds = funds.filter(f => f.fundCode === target.fundCode);
      const currentCost = boughtFunds[0]? boughtFunds[0].daySummary.totalCost : '0';
      const costGap = parseFloat(target.targetCost) - parseFloat(currentCost);

      if (boughtFunds[0] && costGap <= 0) {
        
        newTargetsStatus.push({
          fundCode: target.fundCode,
          fundName: boughtFunds[0].fund.fullName,
          targetPrice: target.targetPrice,
          currentPrice: '未询价',
          priceRate: NaN,
          targetCost: target.targetCost,
          currentCost: currentCost,
          costGap: 0
        });
        continue;
      }

      const today = toLocalISOString(new Date()).slice(0,10);
      let startDate = new Date();
      startDate.setMonth(startDate.getMonth() - 1);
      const sDate = toLocalISOString(startDate).slice(0,10);

      let prices: FundPrice[];
      try {
        prices = await getPriceByFundCodeAndDateRange(target.fundCode, sDate, today, 10000);
      } catch (error) {
        console.error(error);

        newTargetsStatus.push({
          fundCode: target.fundCode,
          fundName: allFunds.filter(f => f.code === target.fundCode)[0].fullName,
          targetPrice: target.targetPrice,
          currentPrice: '询价超时',
          priceRate: NaN,
          targetCost: target.targetCost,
          currentCost: currentCost,
          costGap: costGap > 0 ? costGap : 0
        });
        continue;
      }

      newTargetsStatus.push({
        fundCode: target.fundCode,
        fundName: allFunds.filter(f => f.code === target.fundCode)[0].fullName,
        targetPrice: target.targetPrice,
        currentPrice: prices[prices.length - 1].value,
        priceRate: parseFloat(prices[prices.length - 1].value) / parseFloat(target.targetPrice),
        targetCost: target.targetCost,
        currentCost: currentCost,
        costGap: costGap > 0 ? costGap : 0
      });
    }
    setTargetsStatus(newTargetsStatus);
    setIsLoading(false);
  }

  return (
    <Paper sx={{mt: 1}}>
      <ListItem>
        <Grid container display="flex" alignItems="center">
          <Grid item xs={4}>
            <Button 
              variant="text"
              onClick={() => navigate(`/plans/${planID}`)}
            >
              设置目标
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Typography 
              sx={{margin: 1}} 
              variant="body2" 
              justifyContent="flex-end"
              display="flex" 
              alignItems="center"
            >
              {isLoading ? <CircularProgress sx={{mr:1}} size={20}/> : <></>}
              购买机会
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{cursor: "pointer"}}>
          {isOpen ? (
            <div onClick={()=>{setIsOpen(false)}}>
              <ExpandLess />
            </div>
          ) : (
            <div onClick={()=>{setIsOpen(true);}}>
              <ExpandMore />
            </div>
          )}
        </Box>
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <Divider/>
        {isLoading ? (
          <>
            <Typography 
              sx={{margin: 1}} 
              variant="body2" 
              justifyContent="center"
              display="flex" 
              alignItems="center"
            >
              查询中请稍候!
            </Typography>
            <LinearProgress />
          </>
        ) :  targetsStatus.length > 0 ? (
          <TargetsStatus targets={targetsStatus} />
        ) : (
          <Typography sx={{margin: 1}} variant="body2" align="center">尚未设置购买目标!</Typography>
        ) }
      </Collapse>
    </Paper>
  )
}

export default TargetBlock
