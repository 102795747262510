import { DaySummary, Transaction } from "models"
import { Decimal } from "decimal.js";
import { TransactionType } from "constants/TransactionType";

// Return null if the date is not a trading day.
async function calculateFundSumByDate (
  allTransactions: Transaction[],
  price: string,
  date: string
  ) : Promise<DaySummary | null> {
    
  const transactions = allTransactions.filter(
    t => t.date <= date
  );

  let volumeSum = new Decimal(0), 
      costSum = new Decimal(0),
      costAverage = new Decimal(0),
      valueSum = new Decimal(0),
      floatProfit = new Decimal(0),
      floatRate = new Decimal(0),
      sellProfit = new Decimal(0),
      outAmount = new Decimal(0),
      totalProfit = new Decimal(0),
      totalRate = new Decimal(0),
      volumeSumLast = new Decimal(0);

  for (let i = 0; i< transactions.length; i++) {
    let volume = new Decimal(transactions[i].volume),
        amount = new Decimal(transactions[i].amount),
        price = new Decimal(transactions[i].price),
        fee = new Decimal(transactions[i].fee);
    
    if (transactions[i].type == TransactionType.CashDividend
      || transactions[i].type == TransactionType.Decrement) {
      
      volumeSum = volumeSum.sub(volume);
      costAverage = (costAverage.mul(volumeSumLast).sub(amount))
        .div(volumeSum);
      volumeSum = volumeSum;
      sellProfit = (price.sub(costAverage))
        .mul(volume).sub(fee).add(sellProfit);
      outAmount = outAmount.add(amount);
    } else if (transactions[i].type == TransactionType.Selling) {
      volumeSum = volumeSum.sub(volume);
      volumeSumLast = volumeSum;
      sellProfit = (price.sub(costAverage))
        .mul(volume).sub(fee).add(sellProfit);
      outAmount = outAmount.add(amount);
    } else {
      volumeSum = volumeSum.add(volume);
      costAverage = (costAverage.mul(volumeSumLast).add(amount))
        .div(volumeSum);
      volumeSumLast = volumeSum;
    }

    // progress(40 + 100 * (i / transactions.length));
  }

  costSum = costAverage.mul(volumeSum);
  valueSum = (new Decimal(price)).mul(volumeSum);
  floatProfit = valueSum.sub(costSum);
  totalProfit = floatProfit.add(sellProfit);
  totalRate = (new Decimal(100)).mul(totalProfit)
    .div((costSum.add(outAmount).sub(sellProfit)));

  if (volumeSum.sub(new Decimal('0.00001')).isPositive()) {
    floatRate = (new Decimal(100)).mul(floatProfit).div(costSum);
  } else {
    floatRate = new Decimal(0);
  }

  const fundSum: DaySummary = {
    date: date,
    totalVolume: volumeSum.toFixed(2),
    totalCost: costSum.toFixed(2),
    averageCost: costAverage.toFixed(4),
    price: price,
    value: valueSum.toFixed(2),
    floatProfit: floatProfit.toFixed(2),
    floatRate: floatRate.toFixed(2),
    sellProfit: sellProfit.toFixed(2),
    outAmount: outAmount.toFixed(2),
    totalProfit: totalProfit.toFixed(2),
    totalRate: totalRate.toFixed(2),
    dayRate: ''
  }

  // progress(100);

  return fundSum;
}

export { calculateFundSumByDate }