
export interface Data {
    id: string;
    amount: string;
    volume: string;
    price: string;
    fee: string;
    date: string;
    fundCode: string;
    fundName: string;
    plan: string;
    type: string;
  }
  
  export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
    
  export type Order = 'asc' | 'desc';
    
  export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (
    a: { [key in Key]: number | string | boolean },
    b: { [key in Key]: number | string | boolean },
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  