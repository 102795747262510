import { CircularProgress, Grid, Snackbar } from "@mui/material"
import { DataStore } from "aws-amplify";
import { BarChart, FundList, LineChart, PieChart, SummaryBlock, TargetBlock } from "components"
import { FundTarget,Plan, PlanFund } from "models";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"

function PlanPage() {
  const params = useParams();
  const [plan, setPlan] = useState<Plan>();
  const [funds, setFunds] = useState<PlanFund[]>([]);

  useEffect(() => {
    const subPlanFund = DataStore.observeQuery(PlanFund)
      .subscribe(({ items }) => {
        const filteredItems = items.filter( 
          f => (f.plan?.id === params.planID??'') 
            && (parseFloat(f.daySummary.totalVolume) > 0)
        );
      setFunds(filteredItems);
    });

    const subPlan = DataStore.observeQuery(Plan, (p) =>
      p.id('eq', params.planID??'')
    ).subscribe(({ items }) => {
      if (items.length > 0) setPlan(items[0]);
    });

    return () => {
      subPlanFund.unsubscribe();
      subPlan.unsubscribe();
    };
  }, [params])

  return (
    <div>
      {plan && funds && funds.length > 0 ? (
        <Grid container spacing={2}>
        <Grid item xs={7}>
          <SummaryBlock name={plan.name} summary={plan.daySummary!} />
          {/* {plan.targets && plan.targets.length > 0 ? (
            <TargetBlock planID={plan.id} funds={funds} targets={plan.targets as FundTarget[]} />
          ) : (<></>)} */}
          <TargetBlock planID={plan.id} funds={funds} targets={plan.targets as FundTarget[]} />
          <LineChart historyValues={plan.historyValues!} />
          <BarChart items={funds!}/>
        </Grid>
        <Grid item xs={5}>
          <PieChart items={funds} />
          <FundList funds={funds!} />
        </Grid>
      </Grid>
      ) : (
        <>
          <CircularProgress />
          <Snackbar
            open={true}
            message="无数据，请添加交易记录！"
          />
        </>
      )}
    </div>
  )
}

export default PlanPage
