import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore";
import Decimal from "decimal.js";
import { DaySummary } from "models";
import { IndexValue } from "models";
import { Transaction } from "models";
import { PlanFund } from "models";
import { Plan } from "models";
import { awsDateToDate, toCSTDateISOString } from "utils/time";
import { createPlanFundIfNotExist, updatePlanFundFromTransactions } from "./planFund";

async function updatePlanFundsAndPlan(plan: Plan, isIncremental: boolean, startDate: string = '') {

  console.log(plan.name);

  const planFunds = (await DataStore.query(PlanFund))
    .filter(f => f.plan!.id === plan.id);

  if (planFunds.length === 0) return;

  // update all plan funds
  for (let planFund of planFunds) {
    planFund = await updatePlanFundFromTransactions(planFund, isIncremental, startDate);
  }

  return await updatePlan(plan, isIncremental, startDate);  
}

async function updatePlan(plan: Plan, isIncremental: boolean, startDate: string = '') {
  const planFunds = (await DataStore.query(PlanFund))
    .filter(f => f.plan!.id === plan.id);

  const firstTransaction = (await DataStore.query(
    Transaction,
    Predicates.ALL,
    {sort: s => s.date(SortDirection.ASCENDING)}
  )).filter(t => t.plan!.id === plan.id)[0];

  if (!firstTransaction) {
    return await DataStore.save(
      Plan.copyOf(plan, updated => {
        updated.daySummary = new DaySummary({
          date: '1900-01-01',
          totalVolume: '0',
          totalCost: '0',
          averageCost: '0',
          price: '0',
          value: '0',
          floatProfit: '0',
          floatRate: '0',
          sellProfit: '0',
          outAmount: '0',
          totalProfit: '0',
          totalRate: '0',
          dayRate: '0'
        });
        updated.historyValues = []
      })
    );
  }

  const firstDay = firstTransaction.date;

  let daySum = plan.daySummary?? new DaySummary({
    date: firstDay,
    totalVolume: '0',
    totalCost: '0',
    averageCost: '0',
    price: '0',
    value: '0',
    floatProfit: '0',
    floatRate: '0',
    sellProfit: '0',
    outAmount: '0',
    totalProfit: '0',
    totalRate: '0',
    dayRate: '0'
  });

  let indexValues = isIncremental
    ? plan.historyValues ? [...plan.historyValues] : []
    : [];

  let date = startDate === ''
    ? isIncremental ? daySum.date : firstDay
    : startDate > firstDay ? startDate : firstDay;

  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const endDate: string = toCSTDateISOString(yesterday);

  if (date === endDate && !isNaN(parseFloat(daySum.dayRate))) return plan;

  while (date <= endDate) {
    // calculate index value
    let floatProfitSum = new Decimal(0),
        costSum = new Decimal(0);

    let active = 0;
    let valueExists = false;
    for (const planFund of planFunds) {

      const indexValue = planFund.historyValues.filter(
        item => item && item.date <= date
      ).sort((a, b) => a!.date < b!.date ? 1 : -1)[0];

      if (indexValue) {
        if (indexValue.date === date) {
          valueExists = true;
          active += parseInt(indexValue.active??'0');
        }

        const v = new Decimal(indexValue.value);
        const i = new Decimal(indexValue.index);
        const c = v.div(i);
        const p = v.sub(c);

        costSum = costSum.add(c);
        floatProfitSum = floatProfitSum.add(p);
      }
    }

    if (!valueExists) {
      const nextDate = awsDateToDate(date);
      nextDate.setDate(nextDate.getDate() + 1);
      date = toCSTDateISOString(nextDate);  
      continue;
    }

    let valueSum = costSum.add(floatProfitSum);
    const index = valueSum.div(costSum);

    const indexValue = new IndexValue({
      date: date,
      index: index.toFixed(4),
      value: valueSum.toFixed(2),
      active: active.toString()
    })

    const i = indexValues.findIndex(
      item => item && item.date === date
    );

    if (i !== -1) {
      indexValues[i] = indexValue;
    } else {
      indexValues = [...indexValues, indexValue];
    }

    // Calculate day summary for the last day
    // if (date === endDate) {
      let volumeSum = new Decimal(0), 
        floatProfit = new Decimal(0),
        floatRate = new Decimal(0),
        sellProfit = new Decimal(0),
        outAmount = new Decimal(0),
        totalProfit = new Decimal(0),
        totalRate = new Decimal(0);

      costSum = new Decimal(0);
      valueSum = new Decimal(0);

      for (const planFund of planFunds) {
        volumeSum = volumeSum.add(planFund.daySummary.totalVolume);
        costSum = costSum.add(planFund.daySummary.totalCost);
        valueSum = valueSum.add(planFund.daySummary.value);
        floatProfit = floatProfit.add(planFund.daySummary.floatProfit);
        sellProfit = sellProfit.add(planFund.daySummary.sellProfit);
        outAmount = outAmount.add(planFund.daySummary.outAmount);
        totalProfit = totalProfit.add(planFund.daySummary.totalProfit);
      }

      floatRate = (new Decimal(100)).mul(floatProfit.div(costSum));
      totalRate = (new Decimal(100)).mul(sellProfit.add(floatProfit))
        .div(costSum.add(outAmount).sub(sellProfit));

      let dayRate = new Decimal(0);
      if (indexValues.length > 1){
        indexValues = indexValues.sort((a, b) => {
          if (a && b) return a?.date < b?.date ? 1 : -1;
          else return 1;
        });

        const a = new Decimal(indexValues[0]!.index);
        const b = new Decimal(indexValues[1]!.index);
        dayRate = (new Decimal(100)).mul((a.sub(b)).div(b))
      }

      daySum = new DaySummary({
        date: date,
        totalVolume: volumeSum.toFixed(2),
        totalCost: costSum.toFixed(2),
        averageCost: '',
        price: '',
        value: valueSum.toFixed(2),
        floatProfit: floatProfit.toFixed(2),
        floatRate: floatRate.toFixed(2),
        sellProfit: sellProfit.toFixed(2),
        outAmount: outAmount.toFixed(2),
        totalProfit: totalProfit.toFixed(2),
        totalRate: totalRate.toFixed(2),
        dayRate: dayRate.toFixed(2),
      });
    // }

    // next day
    const nextDate = awsDateToDate(date);
    nextDate.setDate(nextDate.getDate() + 1);
    date = toCSTDateISOString(nextDate);
  }


  const updatedPlan = Plan.copyOf(
    plan, updated => {
      updated.daySummary = daySum;
      updated.historyValues = indexValues;
    }
  );
  
  console.log(updatedPlan.name, updatedPlan);
  await DataStore.save(updatedPlan);

  return updatedPlan;
}

async function recalculatePlanData(plan: Plan) {
  const transactions = (await DataStore.query(Transaction))
    .filter(t => t.plan!.id === plan.id);

  console.log(transactions)

  for (const transaction of transactions) {
    await createPlanFundIfNotExist(transaction);
  }

  await updatePlanFundsAndPlan(plan, false);
}

export { 
  recalculatePlanData, 
  updatePlan, 
  updatePlanFundsAndPlan 
}