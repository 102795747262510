import { useCtx } from '../hooks/useCtx';
import { BarChart, LineChart, PieChart, PlanList, SummaryBlock, Welcome } from 'components';
import { Auth, DataStore } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { UserData } from 'models';
import { Grid } from '@mui/material';

function HomePage() {
  const { plans } = useCtx();

  const [username, setUsername] = useState('');
  const [userData, setUserData] = useState<UserData>();

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      setUsername(user.attributes.name);
    });

    const subUserData = DataStore.observeQuery(UserData)
      .subscribe(({ items }) => {
        setUserData(items[0]);
    });

    return () => {
      subUserData.unsubscribe();
    }
  }, []);


  return (
    <div>
      {userData ? (
        <Grid container spacing={2}>
        <Grid item xs={8}>
          <SummaryBlock name={username} summary={userData.daySummary} />
          <LineChart historyValues={userData.historyValues} />
          <BarChart items={plans}/>
        </Grid>
        <Grid item xs={4}>
          <PieChart items={plans} />
          <PlanList plans={plans} />
        </Grid>
      </Grid>
      ) : (
        <Welcome />
      )}
    </div>
  )
}

export default HomePage
