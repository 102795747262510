import { CircularProgress, Grid } from "@mui/material"
import { DataStore, Predicates, SortDirection } from "aws-amplify";
import { LineChart, SummaryBlock, TransactionTable } from "components"
import { PlanFund, Transaction } from "models";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"

function FundPage() {
  const params = useParams();
  const [fund, setFund] = useState<PlanFund>();
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  useEffect(() => {
    const sub = DataStore.observeQuery(PlanFund, (f) =>
      f.id('eq', params.fundID??'')
    ).subscribe(async ({ items }) => {
      if (items.length > 0) {
        setFund(items[0]);
        const trans = (await DataStore.query(
          Transaction,
          Predicates.ALL,
          {sort: s => s.date(SortDirection.DESCENDING)}
        )).filter( t => t.plan!.id === items[0].plan!.id && t.fundCode === items[0].fundCode);
        setTransactions(trans);
      }
    });

    return () => {
      sub.unsubscribe();
    };
  }, [])

  return (
    <div>
      {fund ? (
        <Grid container spacing={2}>
        <Grid item xs={7}>
          <SummaryBlock name={fund.fund.fullName} summary={fund.daySummary!} />
          <LineChart historyValues={fund.historyValues!} />
          {/* <BarChart items={funds!}/> */}
        </Grid>
        <Grid item xs={5}>
          <TransactionTable transactions={transactions}/>
        </Grid>
      </Grid>
      ) : (
        <CircularProgress />
      )}
    </div>
  )
}

export default FundPage
