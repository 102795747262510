import { Box, Divider, Typography } from "@mui/material"

function Footer() {
  return (
    <Box sx={{ display:'flex', justifyContent:'center', marginTop: 2}}>
      <Divider />
      <Typography gutterBottom variant="subtitle1">
        Bina © 2022 | lwang79@gmail.com | +61420221218
      </Typography>
    </Box>
  )
}

export default Footer
