import { useEffect, useMemo, useState } from 'react';
import Highcharts from "highcharts/highstock";
import { I18n } from 'aws-amplify';
import { withAuthenticator, translations } from '@aws-amplify/ui-react';
import { Container, CssBaseline, LinearProgress, Snackbar, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Footer, Header, Notification, NotificationProps } from './components';
import { Outlet } from 'react-router-dom';
import { DataStore } from '@aws-amplify/datastore';
import { Plan } from 'models';
import '@aws-amplify/ui-react/styles.css';

I18n.putVocabularies(translations);
I18n.setLanguage('zh');

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(
    () => createTheme({
      palette: {
        mode: prefersDarkMode ? 'dark' : 'light',
      },
    }),
    [prefersDarkMode],
  );

  useMemo(() => {
    Highcharts.theme = prefersDarkMode ? {
      accessibility: {
        enabled: false
      },
      chart: {
        backgroundColor: {
          stops: [
              [0, 'rgba(255, 255, 255, 0.05)'],
              [1, 'rgba(255, 255, 255, 0.05)']
          ]
        }
      },
      legend: {
        itemStyle: {
          color: 'rgb(158, 161, 176)'
        },
        itemHoverStyle: {
          color: 'gray'//'rgb(255, 255, 255)'
        }
      },
      title: {
        style: {
          color: 'rgb(158, 161, 176)'
        }
      }
    } : {
      accessibility: {
        enabled: false
      },
      // chart: {
      //   backgroundColor: {
      //     stops: [
      //         [0, 'rgba(255, 255, 255, 0.05)'],
      //         [1, 'rgba(255, 255, 255, 0.05)']
      //     ]
      //   }
      // },
      legend: {
        itemStyle: {
          color: 'rgb(158, 161, 176)'
        },
        itemHoverStyle: {
          color: 'gray'
        }
      },
      title: {
        style: {
          color: 'rgb(158, 161, 176)'
        }
      }
    }

    Highcharts.setOptions(Highcharts.theme);
  }, [prefersDarkMode])

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [plans, setPlans] = useState<Plan[]>([]);

  useEffect(() => {
    const subPlan = DataStore.observeQuery(Plan)
      .subscribe(({ items }) => {
      setPlans(items);
    });

    return () => {
      subPlan.unsubscribe();
    };
  },[])
  
  const [notificationProps, setNotificationProps] = useState<NotificationProps>({
    isOpen: false,
    severity: 'info',
    message: "test"
  });

  const [progress, setProgressState] = useState(0);

  const showNotification = (notification: NotificationProps) => {
    setNotificationProps(notification);
    if (notification.severity === 'success') {
      setTimeout(() => setNotificationProps({...notification, isOpen: false}), 3000);
    }
  }

  const setProgress = (progress: number) => {
    if (progress < 100) {
      setProgressState(progress);
    } else {
      setProgressState(100);
      setTimeout(() => {
        setProgressState(0);
      }, 1000);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header plans={plans} setProgress={setProgress}/>
      <LinearProgress variant="determinate" value={progress} />
      <Notification {...notificationProps} />
      <Container maxWidth="lg">
        <Outlet context={{plans, showNotification, setProgress}}/>
      </Container>
      <Footer />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        message="更新数据中..."
      />
    </ThemeProvider>
  );
}

export default withAuthenticator(App);
