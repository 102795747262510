import { DataStore, Predicates, SortDirection } from "@aws-amplify/datastore"
import Decimal from "decimal.js";
import { DaySummary, Plan } from "models";
import { UserData } from "models";
import { IndexValue } from "models";
import { Transaction } from "models"
import { awsDateToDate, toCSTDateISOString } from "utils/time";
import { updatePlanFundsAndPlan } from "./plan";
import { createPlanFundIfNotExist } from "./planFund";

async function updatePlanAndUserData(isIncremental: boolean, setProgress: Function, startDate: string = '') {

  const userData = await createUserDataIfNotExist();

  let daySum = userData.daySummary;

  let d = new Date(daySum.date.slice(0,10));
  d.setDate(d.getDate() - 3);

  let date = startDate === ''
    ? isIncremental
      ? d.toISOString().slice(0, 10)
      : (await DataStore.query(
          Transaction,
          Predicates.ALL,
          {sort: s => s.date(SortDirection.ASCENDING)}
        ))[0].date
    : startDate;

  const plans = await DataStore.query(Plan);

  for (let i = 0; i < plans.length; i ++) {
    const updatedPlan = await updatePlanFundsAndPlan(plans[i], isIncremental, date);
    if (updatedPlan) plans[i] = updatedPlan;
    setProgress(10 + 90 * (i + 1) / plans.length);
  }

  updateUserData(isIncremental, date);
  setProgress(100);
}

async function updateUserData(isIncremental: boolean, startDate: string = '') {
  const plans = await DataStore.query(Plan);
  if (plans.length === 0) {
    deleteUserData();
    return;
  }

  const userData = await createUserDataIfNotExist();

  let daySum = userData.daySummary;

  let indexValues = isIncremental
    ? userData.historyValues ? [...userData.historyValues] : []
    : [];

  let date = startDate === ''
    ? isIncremental
      ? daySum.date
      : (await DataStore.query(
          Transaction,
          Predicates.ALL,
          {sort: s => s.date(SortDirection.ASCENDING)}
        ))[0].date
    : startDate;

  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const endDate: string = toCSTDateISOString(yesterday);

  if (date === endDate && !isNaN(parseFloat(daySum.dayRate))) return;

  while (date <= endDate) {
    // calculate index value
    let floatProfitSum = new Decimal(0),
        costSum = new Decimal(0);

    let active = 0;
    let valueExists = false;
    for (const plan of plans) {
      if (!plan.historyValues) continue;

      const indexValue = plan.historyValues.filter(
        item => item && item.date <= date
      ).sort((a, b) => a!.date < b!.date ? 1 : -1)[0];

      if (indexValue) {
        if (indexValue.date === date) {
          valueExists = true;
          active += parseInt(indexValue.active??'0');
        }
        
        const v = new Decimal(indexValue.value);
        const i = new Decimal(indexValue.index);
        const c = v.div(i);
        const p = v.sub(c);

        costSum = costSum.add(c);
        floatProfitSum = floatProfitSum.add(p);
      }
    }

    if (!valueExists) {
      const nextDate = awsDateToDate(date);
      nextDate.setDate(nextDate.getDate() + 1);
      date = toCSTDateISOString(nextDate);  
      continue;
    }

    let valueSum = costSum.add(floatProfitSum);
    const index = valueSum.div(costSum);

    const indexValue = new IndexValue({
      date: date,
      index: index.toFixed(4),
      value: valueSum.toFixed(2),
      active: active.toString()
    })

    const i = indexValues.findIndex(
      item => item && item.date === date
    );

    if (i !== -1) {
      indexValues[i] = indexValue;
    } else {
      indexValues = [...indexValues, indexValue];
    }

    // Calculate day summary for the last day
    // if (date == endDate) {
      let volumeSum = new Decimal(0), 
        floatProfit = new Decimal(0),
        floatRate = new Decimal(0),
        sellProfit = new Decimal(0),
        outAmount = new Decimal(0),
        totalProfit = new Decimal(0),
        totalRate = new Decimal(0);

      costSum = new Decimal(0);
      valueSum = new Decimal(0);
      for (const plan of plans) {
        if (!plan.daySummary) continue;

        volumeSum = volumeSum.add(plan.daySummary!.totalVolume);
        costSum = costSum.add(plan.daySummary!.totalCost);
        valueSum = valueSum.add(plan.daySummary!.value);
        floatProfit = floatProfit.add(plan.daySummary!.floatProfit);
        sellProfit = sellProfit.add(plan.daySummary!.sellProfit);
        outAmount = outAmount.add(plan.daySummary!.outAmount);
        totalProfit = totalProfit.add(plan.daySummary!.totalProfit);
      }

      floatRate = (new Decimal(100)).mul(floatProfit.div(costSum));
      totalRate = (new Decimal(100)).mul(sellProfit.add(floatProfit))
        .div(costSum.add(outAmount).sub(sellProfit));

      let dayRate = new Decimal(0);
      if (indexValues.length > 1){
        indexValues = indexValues.sort((a, b) => {
          if (a && b) return a?.date < b?.date ? 1 : -1;
          else return 1;
        });

        const a = new Decimal(indexValues[0]!.index);
        const b = new Decimal(indexValues[1]!.index);
        dayRate = (new Decimal(100)).mul((a.sub(b)).div(b))
      }
            
      daySum = new DaySummary({
        date: date,
        totalVolume: volumeSum.toFixed(2),
        totalCost: costSum.toFixed(2),
        averageCost: '',
        price: '',
        value: valueSum.toFixed(2),
        floatProfit: floatProfit.toFixed(2),
        floatRate: floatRate.toFixed(2),
        sellProfit: sellProfit.toFixed(2),
        outAmount: outAmount.toFixed(2),
        totalProfit: totalProfit.toFixed(2),
        totalRate: totalRate.toFixed(2),
        dayRate: dayRate.toFixed(2),
      });
    // }

    // next day
    const nextDate = awsDateToDate(date);
    nextDate.setDate(nextDate.getDate() + 1);
    date = toCSTDateISOString(nextDate);
  }

  const updatedData = UserData.copyOf(
    userData, updated => {
      updated.daySummary = daySum;
      updated.historyValues = indexValues;
    }
  );

  console.log(updatedData);
  await DataStore.save(updatedData);
}

async function deleteUserData() {
  const result = await DataStore.query(UserData);
  if (result.length != 0) DataStore.delete(result[0]);
}

async function createUserDataIfNotExist() {
  const result = await DataStore.query(UserData);
  if (result.length != 0) return result[0];

  const userData = new UserData({
    daySummary: new DaySummary({
      date: (await DataStore.query(
          Transaction,
          Predicates.ALL,
          {sort: s => s.date(SortDirection.ASCENDING)}
        ))[0].date,
      totalVolume: '',
      totalCost: '',
      averageCost: '',
      price: '',
      value: '',
      floatProfit: '',
      floatRate: '',
      sellProfit: '',
      outAmount: '',
      totalProfit: '',
      totalRate: '',
      dayRate: ''
    }),
    historyValues: []
  });

  return await DataStore.save(userData);
}

async function recalculateData(setProgress: Function) {
  const transactions = await DataStore.query(
    Transaction,
    Predicates.ALL,
    {sort: s => s.date(SortDirection.ASCENDING)}
  );

  const groupedTransactions: Transaction[] = [];

  for (const transaction of transactions) {
    const test = groupedTransactions
      .filter(t => t.plan!.id === transaction.plan!.id)
      .filter(t => t.fundCode === transaction.fundCode);
    
    if (test.length === 0) groupedTransactions.push(transaction);
  }

  setProgress(5);

  for (const transaction of groupedTransactions) {
    await createPlanFundIfNotExist(transaction);
  }

  setProgress(10);

  await updatePlanAndUserData(false, setProgress);

}

export { updatePlanAndUserData, updateUserData, recalculateData }