import { AlertColor, Collapse, IconButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';

export interface NotificationProps {
  isOpen: boolean,
  severity: AlertColor,
  message: string
}

function Notification(props: NotificationProps) {
  const { isOpen, severity, message } = props;
  const [alert, setAlert] = useState<NotificationProps>({isOpen, severity, message});

  useEffect(() => {
    setAlert({isOpen, severity, message});
  },[props]);

  return (
    <Collapse in={alert.isOpen}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => setAlert({...alert, isOpen: false})}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        severity={alert.severity}
      >
        {alert.message}
      </Alert>
    </Collapse>
  )
}

export default Notification
