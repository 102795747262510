import { Button, Grid, Paper, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import { useCtx } from 'hooks/useCtx';

function Welcome() {
  const navigate = useNavigate();
  const {plans} = useCtx();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Paper sx={{padding: 2, mt: 2}}>
            <Typography fontSize={24} align="center">欢迎使用</Typography>
            <Typography fontSize={20} 
              sx={{mt: 2, mb: 1}} 
              display="flex"
              alignItems="center"
            >
              第一步 
              {plans.length > 0 ? (
                <CheckCircleIcon color="success"/>
              ) : (
                <CheckCircleIcon color="disabled"/>
              )}
            </Typography>
            <Button variant="contained"
              size="large"
              fullWidth={true}
              onClick={() => navigate('/plans/new')}
            >
              创建一个新投资计划
            </Button>
            <Typography fontSize={20} 
              sx={{mt: 2, mb: 1}} 
              display="flex"
              alignItems="center"
            >
              第二步 
              <CheckCircleIcon color="disabled"/>
            </Typography>
            <Button variant="contained"
              size="large"
              fullWidth={true}
              onClick={() => navigate('/transactions/new')}
            >
              添加交易记录到投资计划
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </>
  )
}

export default Welcome
