// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Transaction, Plan, PlanFund, UserData, Configuration, FundPrice, FundInfo, DaySummary, IndexValue, FundTarget, FundNotification, MetricsThreshold } = initSchema(schema);

export {
  Transaction,
  Plan,
  PlanFund,
  UserData,
  Configuration,
  FundPrice,
  FundInfo,
  DaySummary,
  IndexValue,
  FundTarget,
  FundNotification,
  MetricsThreshold
};