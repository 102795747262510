import { 
  Box, Button, Card, CardActions, CardContent, 
  Chip, Container, Divider, FormControl, Input, 
  MenuItem, OutlinedInput, Select, SelectChangeEvent, 
  Slider, Stack, Switch, Typography 
} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useCtx } from 'hooks/useCtx';
import { useEffect, useState } from 'react';
import { Configuration, MetricsThreshold } from 'models';
import { DataStore } from 'aws-amplify';
import { Theme, useTheme } from '@mui/material/styles';
import { validateEmail } from 'utils/email';

function ConfigurationPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const {plans, showNotification, setProgress} = useCtx();
  const [planNames, setPlanNames] = useState<string[]>([]);
  const [configuration, setConfiguration] = useState<Configuration>(new Configuration({
    fundNotification: {
      isEnabled: false,
      email: 'your email address',
      plans: [],
      metrics: []
    }
  }));

  const [formData, setFormData] = useState({
    fundNotification: {
      isEnabled: false,
      email: '',
      isFloatProfitLowerThresholdEnabled: false,
      floatProfitLowerThreshold: 0,
      isFloatProfitUpperThresholdEnabled: false,
      floatProfitUpperThreshold: 0,
      isDayRateLowerThresholdEnabled: false,
      dayRateLowerThreshold: 0,
      isDayRateUpperThresholdEnabled: false,
      dayRateUpperThreshold: 0,
      isFloatRateLowerThresholdEnabled: false,
      floatRateLowerThreshold: 0,
      isFloatRateUpperThresholdEnabled: false,
      floatRateUpperThreshold: 0
    }
  })

  useEffect(() => {
    const sub = DataStore.observeQuery(Configuration)
    .subscribe( ({ items }) => {
      if (items.length > 0) {
        convertConfigurationToFormData(items[0]);
        setConfiguration(items[0]);
      } else {
        let names: string[] = [];

        for (const plan of plans) {
          names.push(plan.name);
        }

        setPlanNames(names);
      }
    });

    return () => {
      sub.unsubscribe();
    }
  }, [])

  const convertConfigurationToFormData = (configuration: Configuration) => {
    let fd = {...formData};
    fd.fundNotification.isEnabled = configuration.fundNotification.isEnabled;
    fd.fundNotification.email = configuration.fundNotification.email;

    for (const metrics of configuration.fundNotification.metrics) {
      if (metrics.name === 'floatRate') {
        if(metrics.isUpper) {
          fd.fundNotification.floatRateUpperThreshold = metrics.value;
          fd.fundNotification.isFloatRateUpperThresholdEnabled = true;
        } else {
          fd.fundNotification.floatRateLowerThreshold = metrics.value;
          fd.fundNotification.isFloatRateLowerThresholdEnabled = true;
        }
      } else if (metrics.name === 'dayRate') {
        if(metrics.isUpper) {
          fd.fundNotification.dayRateUpperThreshold = metrics.value;
          fd.fundNotification.isDayRateUpperThresholdEnabled = true;
        } else {
          fd.fundNotification.dayRateLowerThreshold = metrics.value;
          fd.fundNotification.isDayRateLowerThresholdEnabled = true;
        }
      } else if (metrics.name === 'floatProfit') {
        if(metrics.isUpper) {
          fd.fundNotification.floatProfitUpperThreshold = metrics.value;
          fd.fundNotification.isFloatProfitUpperThresholdEnabled = true;
        } else {
          fd.fundNotification.floatProfitLowerThreshold = metrics.value;
          fd.fundNotification.isFloatProfitLowerThresholdEnabled = true;
        }
      }
    }

    setFormData(fd);

    let names: string[] = [];

    for (const planID of configuration.fundNotification.plans) {
      const plan = plans.filter(p => p.id === planID)[0];
      names.push(plan.name);
    }

    setPlanNames(names);
  }

  const convertFormDataToConfiguration = (): Configuration => {
    let metrics: MetricsThreshold[] = []

    if (formData.fundNotification.isFloatRateUpperThresholdEnabled) {
      metrics.push({
        name: 'floatRate',
        isUpper: true,
        value: formData.fundNotification.floatRateUpperThreshold
      });
    }
    if (formData.fundNotification.isFloatRateLowerThresholdEnabled) {
      metrics.push({
        name: 'floatRate',
        isUpper: false,
        value: formData.fundNotification.floatRateLowerThreshold
      });
    }

    if (formData.fundNotification.isDayRateUpperThresholdEnabled) {
      metrics.push({
        name: 'dayRate',
        isUpper: true,
        value: formData.fundNotification.dayRateUpperThreshold
      });
    }
    if (formData.fundNotification.isDayRateLowerThresholdEnabled) {
      metrics.push({
        name: 'dayRate',
        isUpper: false,
        value: formData.fundNotification.dayRateLowerThreshold
      });
    }

    if (formData.fundNotification.isFloatProfitUpperThresholdEnabled) {
      metrics.push({
        name: 'floatProfit',
        isUpper: true,
        value: formData.fundNotification.floatProfitUpperThreshold
      });
    }
    if (formData.fundNotification.isFloatProfitLowerThresholdEnabled) {
      metrics.push({
        name: 'floatProfit',
        isUpper: false,
        value: formData.fundNotification.floatProfitLowerThreshold
      });
    }

    let planIDs: string[] = [];
    for (const name of planNames) {
      const plan = plans.filter(p => p.name === name)[0];
      planIDs.push(plan.id);
    }

    let conf = new Configuration({
      fundNotification: {
        isEnabled: formData.fundNotification.isEnabled,
        email: formData.fundNotification.email,
        plans: planIDs,
        metrics: metrics
      }
    });

    return conf;
  }

  const setNotification = (key: string)=> (event: any, newValue: any) => {
    setFormData({
      ...formData,
      fundNotification:{
        ...formData.fundNotification,
        [key]: newValue//event.target.checked? event.target.checked : event.target.newValue
      }
    });
  }

  const setInput = (key: string)=> (event: any) => {
    setFormData({
      ...formData,
      fundNotification:{
        ...formData.fundNotification,
        [key]: event.target.value
      }
    });
  }

  const setPlan = (event: SelectChangeEvent<typeof planNames>) => {
    const {
      target: { value },
    } = event;
    setPlanNames(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const saveClickHandler = async () => {
    const conf = convertFormDataToConfiguration();

    if (conf.fundNotification.plans.length < 1) {
      showNotification({isOpen: true, severity: "error", message: "投资组合不能为空!"});
      return;
    }

    if (!validateEmail(conf.fundNotification.email)) {
      showNotification({isOpen: true, severity: "error", message: "邮箱格式错误!"});
      return;
    }

    let toSaveConfiguration = Configuration.copyOf(configuration, (draft) => {
      draft.fundNotification = conf.fundNotification;
    })

    await DataStore.save(toSaveConfiguration);
    showNotification({isOpen: true, severity: 'success', message: '设置已保存！'});
    navigate(-1);
  }

  const getStyles = (plan: string, plans: readonly string[], theme: Theme) => {
    return {
      fontWeight:
        plans.indexOf(plan) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  return (
    <>
      <Container maxWidth="sm" sx={{mt:2}}>
        <Card>
          <CardContent>
            <Typography fontSize={24} align="center">设置</Typography>
            <Divider/>
            <Stack direction="row" spacing={1} alignItems="center" sx={{mt: 2, mb: 1}}>
              <Typography fontSize={20}>涨跌通知</Typography>
              <Switch 
                checked={formData.fundNotification.isEnabled}
                onChange={setNotification('isEnabled')}
              />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
              <Typography sx={{my:2}}>接收通知邮箱</Typography>
              <Box sx={{width: 400, maxWidth: '100%'}}>
                <FormControl fullWidth>
                  <Input
                    disabled={!formData.fundNotification.isEnabled}
                    value={formData.fundNotification.email}
                    onChange={setInput('email')}
                  />
                </FormControl>
              </Box>
            </Stack>
            <Typography sx={{my:2}}>选择需要观察的投资组合</Typography>
            <FormControl fullWidth variant="standard">
              <Select
                multiple
                value={planNames}
                onChange={setPlan}
                disabled={!formData.fundNotification.isEnabled}
                input={<OutlinedInput/>}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {plans.map((plan, index)=>(
                  <MenuItem 
                    key={index} 
                    value={plan.name}
                    style={getStyles(plan.name, planNames, theme)}
                  >
                    {plan.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography sx={{my:2}}>设置上限：数值大于等于上限时通知</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>浮动收益率（%）</Typography>
              <Switch 
                checked={formData.fundNotification.isFloatRateUpperThresholdEnabled}
                onChange={setNotification('isFloatRateUpperThresholdEnabled')}
                disabled={!formData.fundNotification.isEnabled}
              />
            </Stack>
            <Slider 
              key={`floatRateUpperThreshold-${formData.fundNotification.floatRateUpperThreshold}`}
              defaultValue={formData.fundNotification.floatRateUpperThreshold}
              min={-100}
              max={100}
              valueLabelDisplay="auto"
              disabled={!formData.fundNotification.isEnabled || !formData.fundNotification.isFloatRateUpperThresholdEnabled}
              onChangeCommitted={setNotification('floatRateUpperThreshold')}
            />
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>前日收益率（%）</Typography>
              <Switch 
                checked={formData.fundNotification.isDayRateUpperThresholdEnabled}
                onChange={setNotification('isDayRateUpperThresholdEnabled')}
                disabled={!formData.fundNotification.isEnabled}
              />
            </Stack>
            <Slider 
              key={`dayRateUpperThreshold-${formData.fundNotification.dayRateUpperThreshold}`}
              defaultValue={formData.fundNotification.dayRateUpperThreshold}
              min={-100}
              max={100}
              valueLabelDisplay="auto"
              disabled={!formData.fundNotification.isEnabled || !formData.fundNotification.isDayRateUpperThresholdEnabled}
              onChangeCommitted={setNotification('dayRateUpperThreshold')}
            />
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>浮动收益金额（¥）</Typography>
              <Switch 
                checked={formData.fundNotification.isFloatProfitUpperThresholdEnabled}
                onChange={setNotification('isFloatProfitUpperThresholdEnabled')}
                disabled={!formData.fundNotification.isEnabled}
              />
            </Stack>
            <Slider 
              key={`floatProfitUpperThreshold-${formData.fundNotification.floatProfitUpperThreshold}`}
              defaultValue={formData.fundNotification.floatProfitUpperThreshold}
              min={-10000}
              max={10000}
              valueLabelDisplay="auto"
              disabled={!formData.fundNotification.isEnabled || !formData.fundNotification.isFloatProfitUpperThresholdEnabled}
              onChangeCommitted={setNotification('floatProfitUpperThreshold')}
            />
            <Typography sx={{my:2}}>设置下限：数值小于等于下限时通知</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>浮动收益率（%）</Typography>
              <Switch 
                checked={formData.fundNotification.isFloatRateLowerThresholdEnabled}
                onChange={setNotification('isFloatRateLowerThresholdEnabled')}
                disabled={!formData.fundNotification.isEnabled}
              />
            </Stack>
            <Slider 
              key={`floatRateLowerThreshold-${formData.fundNotification.floatRateLowerThreshold}`}
              defaultValue={formData.fundNotification.floatRateLowerThreshold}
              min={-100}
              max={100}
              valueLabelDisplay="auto"
              disabled={!formData.fundNotification.isEnabled || !formData.fundNotification.isFloatRateLowerThresholdEnabled}
              onChangeCommitted={setNotification('floatRateLowerThreshold')}
            />
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>前日收益率（%）</Typography>
              <Switch 
                checked={formData.fundNotification.isDayRateLowerThresholdEnabled}
                onChange={setNotification('isDayRateLowerThresholdEnabled')}
                disabled={!formData.fundNotification.isEnabled}
              />
            </Stack>
            <Slider 
              key={`dayRateLowerThreshold-${formData.fundNotification.dayRateLowerThreshold}`}
              defaultValue={formData.fundNotification.dayRateLowerThreshold}
              min={-100}
              max={100}
              valueLabelDisplay="auto"
              disabled={!formData.fundNotification.isEnabled || !formData.fundNotification.isDayRateLowerThresholdEnabled}
              onChangeCommitted={setNotification('dayRateLowerThreshold')}
            />
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>浮动收益金额（¥）</Typography>
              <Switch 
                checked={formData.fundNotification.isFloatProfitLowerThresholdEnabled}
                onChange={setNotification('isFloatProfitLowerThresholdEnabled')}
                disabled={!formData.fundNotification.isEnabled}
              />
            </Stack>
            <Slider 
              key={`floatProfitLowerThreshold-${formData.fundNotification.floatProfitLowerThreshold}`}
              defaultValue={formData.fundNotification.floatProfitLowerThreshold}
              min={-10000}
              max={10000}
              valueLabelDisplay="auto"
              disabled={!formData.fundNotification.isEnabled || !formData.fundNotification.isFloatProfitLowerThresholdEnabled}
              onChangeCommitted={setNotification('floatProfitLowerThreshold')}
            />
          </CardContent>
          <CardActions>
            <Button
              sx = {{ marginLeft: 'auto'}}
              onClick={saveClickHandler}
              size='large'
            >
              保存
            </Button>
            <Button 
              color="error"
              onClick={()=>navigate(-1)}
              size='large'
            >
              取消
            </Button>
          </CardActions>
        </Card>
      </Container>
    </>
  )
}

export default ConfigurationPage
