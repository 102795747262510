import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import { Amplify, DataStore } from 'aws-amplify';

import awsconfig from './aws-exports';

import HomePage from './pages/HomePage';
import PlanForm from './pages/PlanForm';
import TransactionForm from './pages/TransactionForm';
import TransactionPage from './pages/TransactionPage';
import TransactionScan from './pages/TransactionScan';
import PlanPage from './pages/PlanPage';
import FundPage from 'pages/FundPage';
import ConfigurationPage from 'pages/ConfigurationPage';

Amplify.configure(awsconfig);
DataStore.configure({syncPageSize: 10});
// Amplify.Logger.LOG_LEVEL = 'DEBUG';
// DataStore.start();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<HomePage />} />
          <Route path="home" element={<HomePage />} />
          <Route path="configuration" element={<ConfigurationPage />} />
          <Route path="plans" >
            <Route path=":planID" element={<PlanForm />} />
            <Route path="new" element={<PlanForm />} />
            <Route path="dashboard" element={<PlanPage />} />
          </Route>
          <Route path="transactions" >
            <Route path=":transactionID" element={<TransactionForm />} />
            <Route path="new" element={<TransactionForm />} />
            <Route path="list" element={<TransactionPage />} />
            <Route path="scan" element={<TransactionScan />} />
          </Route>
          <Route path="dashboard">
            <Route path="plan/:planID" element={<PlanPage />} />
            <Route path="fund/:fundID" element={<FundPage />} />
          </Route>
        </Route>
      </Routes>
  </BrowserRouter>
);