import { DataStore, SortDirection } from "@aws-amplify/datastore";
import { TransactionType } from "constants/TransactionType";
import Decimal from "decimal.js";
import { DaySummary, FundPrice, IndexValue, Transaction } from "models";
import { PlanFund } from "models"
import { awsDateToDate, toCSTDateISOString } from "utils/time";
import { getPriceByFundCodeAndDateRange } from "./fundPrice";
import { calculateFundSumByDate } from "./fundSum";

async function createPlanFundIfNotExist(transaction: Transaction) {
  const allPlanFunds = await DataStore.query(PlanFund);
  const result = allPlanFunds.filter(
    f => f.fundCode === transaction.fundCode 
      && f.plan!.id === transaction.plan!.id
  );

  if (result.length != 0) return result[0];

  const planFund = new PlanFund({
    fundCode: transaction.fundCode,
    fund: transaction.fund,
    plan: transaction.plan,
    daySummary: new DaySummary({
      date: transaction.date,
      totalVolume: '0',
      totalCost: '0',
      averageCost: '0',
      price: '0',
      value: '0',
      floatProfit: '0',
      floatRate: '0',
      sellProfit: '0',
      outAmount: '0',
      totalProfit: '0',
      totalRate: '0',
      dayRate: '0'
    }),
    historyValues: []
  });
  const savedPlanFund = await DataStore.save(planFund);
  return savedPlanFund;
}

async function updatePlanFundFromTransactions(planFund: PlanFund, isIncremental: boolean , startDate: string = '') {

  const transactions = (await DataStore.query(
    Transaction,
    (t) => t.fundCode('eq', planFund.fundCode),
    { sort: s => s.date(SortDirection.ASCENDING)}
  )).filter(t => t.plan!.id === planFund.plan!.id);

  if (transactions.length === 0) {
    console.info(`All transactions of this fund(${planFund.fundCode}) has been deleted. Delete this PlanFund`);
    await DataStore.delete(planFund);
    return planFund;
  }

  let daySum: DaySummary = planFund.daySummary;

  let indexValues = isIncremental
    ? planFund.historyValues ? [...planFund.historyValues] : []
    : [];

  if (startDate <= transactions[0].date) indexValues = [];

  let date = startDate === ''
    ? isIncremental ? daySum.date : transactions[0].date
    : startDate > transactions[0].date ? startDate : transactions[0].date;

  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const endDate: string = toCSTDateISOString(yesterday);

  if (date === endDate
    && !isNaN(parseFloat(daySum.floatRate))
    ) return planFund;

  let pricesAreFetched = false;
  let tryTimes = 0;
  let prices: FundPrice[] = [];
  while (!pricesAreFetched && tryTimes < 3) {
    try {
      prices = await getPriceByFundCodeAndDateRange(planFund.fundCode, date, endDate);
      pricesAreFetched = true;
    } catch (err) {
      console.error(err);
    } finally {
      tryTimes ++;
    }
  }

  while (date <= endDate) {
    const price = prices.filter(p => p.date === date);
    if (price.length > 0) {
      const ds = await calculateFundSumByDate(transactions, price[0].value, date);

      if (ds) {
        daySum = ds;

        let active = 0;
        const trans = transactions.filter(t => t.date === date);
        if (trans.length > 0) {
          if (trans[0].type === TransactionType.Buying) {
            active = 1;
          } else if (trans[0].type === TransactionType.Selling) {
            active = -1;
          }
        }
        
        const indexValue: IndexValue = {
          date: date,
          index: (1 + parseFloat(daySum.floatRate) / 100).toFixed(4),
          value: daySum.value,
          active: active.toString()
        }
        
        const i = indexValues.findIndex(
          item => item && item.date === date
        );
  
        if (i !== -1) {
          indexValues[i] = indexValue;
        } else {
          indexValues = [...indexValues, indexValue];
        }
      }
    }

    const nextDate = awsDateToDate(date);
    nextDate.setDate(nextDate.getDate() + 1);
    date = toCSTDateISOString(nextDate);
  }

  let dayRate = new Decimal(0);
  if (indexValues.length > 1){
    indexValues = indexValues.sort((a, b) => {
      if (a && b) return a?.date < b?.date ? 1 : -1;
      else return 1;
    });

    const a = new Decimal(indexValues[0]!.index);
    const b = new Decimal(indexValues[1]!.index);
    dayRate = (new Decimal(100)).mul((a.sub(b)).div(b))
  }

  const newDaySum = {
    ...daySum,
    dayRate: dayRate.toFixed(2)
  }

  const updatedPlanFund = PlanFund.copyOf(
    planFund, updated => {
      updated.daySummary = newDaySum;
      updated.historyValues = indexValues;
    }
  );

  console.log(updatedPlanFund.fundCode, updatedPlanFund)
  await DataStore.save(PlanFund.copyOf(updatedPlanFund, a => {}));
  return updatedPlanFund;
}

export { 
  createPlanFundIfNotExist, 
  updatePlanFundFromTransactions 
}