import { Grid, Paper, Typography } from '@mui/material'
import TargetBlock from 'components/TargetBlock/TargetBlock'
import { DaySummary, FundTarget, Plan, PlanFund } from 'models'
import { showCurrency } from 'utils/currency'

interface SummaryBlockProps {
  name: String,
  summary: DaySummary
}
function SummaryBlock( { name, summary}: SummaryBlockProps) {
  return (
    <>
      <Paper sx={{padding: 2,  marginTop: 1}}>
        <Grid container  alignItems="flex-end">
          <Grid item xs={4}>
            <Typography gutterBottom variant="h6">
              {name}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography gutterBottom variant="h5">
              市值：{showCurrency(summary.value)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography gutterBottom variant="subtitle1" color="text.secondary" align="center">
              {summary.date.slice(0,10)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" color="text.secondary">
              净值：{(1+parseFloat(summary.floatRate)/100).toFixed(4)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" color="text.secondary">
              浮动收益：{showCurrency(summary.floatProfit)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" color="text.secondary">
              总收益：{showCurrency(summary.totalProfit)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" color="text.secondary">
              涨跌：{summary.dayRate[0] === '-' ? (
                <span style={{color: 'green'}}>{summary.dayRate}%</span>
              ) : (
                <span style={{color: 'red'}}>{summary.dayRate}%</span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" color="text.secondary">
              浮动收益率：{summary.floatRate[0] === '-' ? (
                <span style={{color: 'green'}}>{summary.floatRate}%</span>
              ) : (
                <span style={{color: 'red'}}>{summary.floatRate}%</span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" color="text.secondary">
              总收益率：{summary.totalRate[0] === '-' ? (
                <span style={{color: 'green'}}>{summary.totalRate}%</span>
              ) : (
                <span style={{color: 'red'}}>{summary.totalRate}%</span>
              )}
            </Typography>
          </Grid>
          {(() => {
            if (summary.averageCost !== '') {
              return (
                <>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="text.secondary">
                      总成本：{showCurrency(summary.totalCost)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="text.secondary">
                      平均成本：{summary.averageCost}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" color="text.secondary">
                      当日价格：{summary.price}
                    </Typography>
                  </Grid>
                </>
              )
            }
          })()}
        </Grid>
      </Paper>
    </>
  )
}

export default SummaryBlock
