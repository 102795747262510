
const toLocalISOString = (date: Date) => {
  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  return (new Date(date.getTime() - tzoffset)).toISOString().slice(0, -1);
}

const toCSTISOString = (date: Date) => {
  return (new Date(date.getTime() + 8*3600*1000)).toISOString().slice(0, -1);
}

const toCSTDateISOString = (date: Date) => {
  return (new Date(date.getTime() + 8*3600*1000)).toISOString().slice(0, 10) + '+08:00';
}

const awsDateToDate = (date: string) => {
  return new Date(`${date.slice(0, 10)}T00:00${date.slice(10, 16)}`);
}

export { awsDateToDate, toLocalISOString, toCSTISOString, toCSTDateISOString}