import { Button, Divider, Grid, IconButton, List, ListItemButton, Paper } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';import { PlanFund } from 'models'
import { useEffect, useState } from 'react';
import FundItem from './FundItem';
import { Box } from '@mui/system';

interface FundListProps {
  funds: PlanFund[]
}

function FundList({ funds }: FundListProps) {
  const [sortingStatus, setSortingStatus] = useState({
    name: 'value',
    ascend: false
  })

  const [sortedFunds, setSortedFunds] = useState(
    [...funds].sort((a, b) => {
      return parseFloat(a.daySummary.value) < parseFloat(b.daySummary.value) ? 1 : -1;
    })
  );

  useEffect(() => {
    setSortedFunds(
      [...funds].sort((a, b) => {
        return parseFloat(a.daySummary.value) < parseFloat(b.daySummary.value) ? 1 : -1;
      })
    )
  },[funds])

  const sortItems = ['value', 'profit', 'profitRate', 'dayRate'];
  const sortNames = ['净值', '收益', '收益率', '当日']
  
  const sort = (name: string) => {
    if (name === 'value') {
      if (sortingStatus.ascend) {
        setSortedFunds(
          [...funds].sort((a, b) => {
            return parseFloat(a.daySummary.value) < parseFloat(b.daySummary.value) ? 1 : -1;
          })
        );
      } else {
        setSortedFunds(
          [...funds].sort((a, b) => {
            return parseFloat(a.daySummary.value) > parseFloat(b.daySummary.value) ? 1 : -1;
          })
        );
      }
    } else if (name === 'profit') {
      if (sortingStatus.ascend) {
        setSortedFunds(
          [...funds].sort((a, b) => {
            return parseFloat(a.daySummary.floatProfit) < parseFloat(b.daySummary.floatProfit) ? 1 : -1;
          })
        );
      } else {
        setSortedFunds(
          [...funds].sort((a, b) => {
            return parseFloat(a.daySummary.floatProfit) > parseFloat(b.daySummary.floatProfit) ? 1 : -1;
          })
        );
      }
    } else if (name === 'profitRate') {
      if (sortingStatus.ascend) {
        setSortedFunds(
          [...funds].sort((a, b) => {
            return parseFloat(a.daySummary.floatRate) < parseFloat(b.daySummary.floatRate) ? 1 : -1;
          })
        );
      } else {
        setSortedFunds(
          [...funds].sort((a, b) => {
            return parseFloat(a.daySummary.floatRate) > parseFloat(b.daySummary.floatRate) ? 1 : -1;
          })
        );
      }
    } else {
      if (sortingStatus.ascend) {
        setSortedFunds(
          [...funds].sort((a, b) => {
            return parseFloat(a.daySummary.dayRate) < parseFloat(b.daySummary.dayRate) ? 1 : -1;
          })
        );
      } else {
        setSortedFunds(
          [...funds].sort((a, b) => {
            return parseFloat(a.daySummary.dayRate) > parseFloat(b.daySummary.dayRate) ? 1 : -1;
          })
        );
      }
    }
    setSortingStatus({ name: name, ascend: !sortingStatus.ascend });
  }

  return (
    <Paper sx={{marginTop: 2}}>
      <Grid container 
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        spacing={1}
      >
        {sortItems.map((item, index) => {
          return (
            <Grid item key={index}>
              <Box textAlign="center">
                <Button endIcon={
                    sortingStatus.name != item ? (
                      <UnfoldMoreIcon color='disabled'/>
                    ) : sortingStatus.ascend ? (
                      <KeyboardArrowUpIcon color='primary'/>
                    ) : (
                      <KeyboardArrowDownIcon color='primary'/>
                    )
                  }
                  onClick={() => {sort(item)}}
                  
                >
                  {sortNames[index]}
                </Button>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <List component="nav">
        {sortedFunds.map((fund, index) => {
          return (
            <div key={fund.id}>
              <Divider/>
              <FundItem fund={fund}/>
            </div>
          )
        })}
      </List>      
    </Paper>
  )
}

export default FundList
